/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Container = styled.aside`
  font-family: 'Raleway', sans-serif;
  font-size: 1.1rem;
  background: #c94c320a;
  position: relative;
  padding: 2.4em 0 1.4rem 0;
  display: flex;
  justify-content: space-around;
  border-radius: 10px;
  margin: 2rem 0;

  ul {
    list-style-type: none;
    width: 80%;

    li {
      margin-bottom: 1.2rem;

      /* :nth-child(1) {
        margin-top: 1rem;
      } */
    }

    li a {
      text-decoration: underline;
      color: ${props => (props.plainlink ? '' : ({ theme }) => theme.primary)};
      padding-bottom: 0.2rem;
      font-weight: 600;
      font-size: 1.25rem;

      :hover {
        font-weight: 700;
      }
    }

    p {
      line-height: 1.5rem;
      padding-top: 0.2rem;

      a {
        color: #000;
        font-weight: 400;
        font-style: normal;
        font-size: 1.1rem;
      }
    }
  }

  @media (min-width: 600px) {
    width: 100%;
    font-size: 1.1rem;
  }

  @media (min-width: 970px) {
    width: 370px;
  }
`;

export const Header = styled.div`
  position: absolute;
  top: -24px;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.primary};
  border-radius: 10px;
  padding: 0.2rem 1.3rem;
  margin-bottom: 1rem;
  margin: auto;
  width: fit-content;
  max-width: 100%;

  p {
    color: ${({ theme }) => theme.textLight};
    text-align: center;
    font-weight: 700;
    padding: 5px 0;
  }
`;
