/* eslint-disable react/prop-types */
import React from 'react';
import { Container, Header } from './styles';

const BoxReferencias = ({ title, children }) => {
  return (
    <Container>
      <Header>
        <p>{title}</p>
      </Header>
      <ul>{children}</ul>
    </Container>
  );
};

export default BoxReferencias;
