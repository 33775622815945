/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Article = styled.article`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  :hover {
    cursor: pointer;
    opacity: 80%;
  }

  @media (min-width: 600px) {
    width: 370px;
    margin-bottom: 5rem;
  }

  a {
    font-family: 'Rasa', serif;
    font-size: 1.8rem;
    font-weight: 600;
    color: ${({ theme }) => theme.textLight};
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    @media (min-width: 600px) {
      //position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: 100%;
      color: ${({ theme }) => theme.textDark};
      width: 90%;
    }
  }

  h2 {
    position: absolute;
    top: 40%;
    z-index: 3;

    :hover {
      cursor: auto;
    }

    @media (min-width: 600px) {
      position: relative;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      // bottom: 104%;
      text-align: center;
      color: ${({ theme }) => theme.primary};
      border-color: ${({ theme }) => theme.primary};
    }
  }
`;

export const Image = styled.div`
  background: #c4c4c4;
  background-image: url(${props => props.imageLink});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 350px;
  // margin-bottom: 2rem;
  padding: 20px;
  margin-top: 1rem;
  position: relative;
  object-fit: fill;

  :before {
    content: '';
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  a {
    color: transparent;
  }

  @media (min-width: 600px) {
    height: 300px;

    :before {
      content: none;
    }
  }
`;

export const Title = styled.h1`
  position: absolute;
  bottom: 32%;
  width: 85%;
  font-size: 1.5rem;
  height: 50px;

  @media (min-width: 600px) {
    position: relative;
    font-size: 1.8rem;
    margin-top: 1.2rem;
  }
`;
