import React from 'react';
import { ThemeProvider } from 'styled-components';
import Routes from './routes';
import GlobalStyle from './styles/global';
import { defaultColor } from './styles/theme';

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={defaultColor}>
        <Routes />
        <GlobalStyle />
      </ThemeProvider>
    </div>
  );
}

export default App;
