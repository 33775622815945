/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Paragraph = styled.p`
  font-family: 'Raleway', sans-serif;
  line-height: 1.75rem;
  padding-bottom: 1.3rem;
  font-weight: 300;
  font-size: 1.1rem;
  width: 100%;
  max-width: 100vw;
  overflow-wrap: break-word;

  a {
    text-decoration: underline;
  }

  u {
    text-decoration-color: ${({ theme }) => theme.primary};
  }

  span {
    color: ${({ theme }) => theme.primary};
    font-style: italic;
    font-weight: 800;
  }

  b {
    font-weight: 700;
  }

  strong {
    font-family: 'Rasa', serif;
    font-size: 1.5rem;
    padding-bottom: 0.3rem;
    color: ${({ theme }) => theme.primary};

    @media (min-width: 600px) {
      font-size: 1.8rem;
    }
  }

  @media (min-width: 600px) {
    line-height: 2.1rem;
    max-width: 900px;
    width: 100%;
    font-size: 1.3rem;
  }
`;
