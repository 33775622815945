/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-unused-vars */
import React from 'react';
import Article from '../../components/Article';
import FooterLeiaMais from '../../components/FooterLeiaMais';
import Header from '../../components/Header';
import ArticleHeader from '../../components/ArticleHeader';
import Paragraph from '../../components/Paragraph';
import { Content } from './styles';

const ReferenciasBibliograficas = () => {
  return (
    <>
      <Header />
      <Content>
        <Article>
          <ArticleHeader
            title="Referências Bibliográficas"
            date="12 de julho de 2021"
          />
          <Paragraph>
            CASTELUCCI, Giovani. <b>O processo criativo enquanto travessia</b>.
            Revista Recorte, 2021. Disponível em:{' '}
            <a href="https://revistarecorte.com.br/artigos/o-processo-criativo-enquanto-travessia/">
              https://revistarecorte.com.br/artigos/o-processo-criativo-enquanto-travessia/
            </a>
            . Acesso em 14 de maio de 2021.
          </Paragraph>
          <br />
          <Paragraph>
            CEDECOM. <b>Tutorial de Audiodescrição</b>. 2020. Disponível em:{' '}
            <a href="https://www.ufmg.br/semanadoconhecimento/wp-content/uploads/2020/09/Tutorial-de-Audiodescri%C3%A7%C3%A3o.pdf">
              https://www.ufmg.br/semanadoconhecimento/wp-content/uploads/2020/09/Tutorial-de-Audiodescri%C3%A7%C3%A3o.pdf
            </a>
            . Acesso em: 6 de junho de 2021.
          </Paragraph>
          <br />
          <Paragraph>
            <b>Diagrama 20</b>: Guilherme Falcão – Design, Conteúdo e
            Autopublicação. Entrevistado: Guilherme Falcão. Entrevistador:
            Rogério Lionzo. 2019. Podcast. Disponível em:{' '}
            <a href="https://soundcloud.com/diagramaco/diagrama-20-guilherme-falcao-design-conteudo-e-autopublicacao">
              https://soundcloud.com/diagramaco/diagrama-20-guilherme-falcao-design-conteudo-e-autopublicacao
            </a>
            . Acesso em: 29 de março de 2021.
          </Paragraph>
          <br />
          <Paragraph>
            ESCALEIRA, Carla Fernandez.{' '}
            <b>
              O impacto das métricas de audiência na rotina de produção
              jornalística dos portais UOL e Estadão
            </b>
            . Dissertação (mestrado em Produção Jornalística e Mercado). Escola
            Superior de Propaganda e Marketing. São Paulo, p. 153, 2020.
          </Paragraph>
          <br />
          <Paragraph>
            ESTADÃO.{' '}
            <b>
              &lsquo;Estadão&lsquo; inicia nova fase e avança sua transformação
              digital
            </b>
            . 2019. Disponível em:{' '}
            <a href="https://www.estadao.com.br/infograficos/economia,estadao-inicia-nova-fase-e-avanca-em-sua-transformacao-digital,1030420">
              https://www.estadao.com.br/infograficos/economia,estadao-inicia-nova-fase-e-avanca-em-sua-transformacao-digital,1030420/
            </a>
            . Acesso em: 25 de maio de 2021
          </Paragraph>
          <br />
          <Paragraph>
            FESSENDEN, Therese. <b>Scrolling and Attention</b>. Nielsen Norman
            Group, 2018. Disponível em:{' '}
            <a href="https://www.nngroup.com/articles/scrolling-and-attention/">
              https://www.nngroup.com/articles/scrolling-and-attention/
            </a>
            . Acesso em 14 de maio de 2021.
          </Paragraph>
          <br />
          <Paragraph>
            LUPTON, Ellen. <b>Pensar com tipos</b>: guia para designers,
            escritores, editores e estudantes. São Paulo: Cosac Naify, 2006.
          </Paragraph>
          <br />
          <Paragraph>
            MEDEIROS, Yara. <b>Jornalismo Visual:</b>. Formas e competências em
            ascensão. In: Intercom – Sociedade Brasileira de Estudos
            Interdisciplinares da Comunicação. 43º Congresso Brasileiro de
            Ciências da Comunicação, 2020, virtual p 1-15.
          </Paragraph>
          <br />
          <Paragraph>
            MORAES, Ary. <b>Design de Notícias</b> – A Acessibilidade do
            Cotidiano. São Paulo: Blucher, 2015
          </Paragraph>
          <br />
          <Paragraph>
            SACCHITIELLO, Bárbara.{' '}
            <b>Grandes jornais têm caminho de crescimento no digital. </b>Meio e
            Mensagem, 2021. Disponível em:{' '}
            <a href="https://www.meioemensagem.com.br/home/midia/2021/02/04/grandes-jornais-tem-caminho-de-crescimento-no-digital.html">
              https://www.meioemensagem.com.br/home/midia/2021/02/04/grandes-jornais-tem-caminho-de-crescimento-no-digital.html
            </a>
            . Acesso em: 26 de maio de 2021.
          </Paragraph>
          <br />
          <Paragraph>
            SANTOS, Yara Medeiros dos.{' '}
            <b>
              Jornalismo visual nas narrativas da grande reportagem brasileira
            </b>
            . Tese (Doutorado em Comunicação) – Centro de Artes e Comunicação,
            Universidade Federal de Pernambuco. Recife p.424. 2020.
          </Paragraph>
          <br />
          <Paragraph>
            ZAO-SANDERS, Marc.{' '}
            <b>Hiring Identify — and Hire — Lifelong Learners. </b>. Harvard
            Business Review, 2021. Disponível em:
            <a href="https://hbr.org/2021/05/identify-and-hire-lifelong-learners">
              https://hbr.org/2021/05/identify-and-hire-lifelong-learners
            </a>
            . Acesso em 15 de maio de 2021.
          </Paragraph>
          <br />
        </Article>
      </Content>
      <FooterLeiaMais />
    </>
  );
};

export default ReferenciasBibliograficas;
