import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.background};
`;

export const Content = styled.div`
  @media (min-width: 600px) {
    width: 970px;
  }

  @media (min-width: 1200px) {
    width: 1170px;
  }
`;

export const Hero = styled.div`
  padding: 3rem 0 4rem 0;
  background: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.textLight};
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    text-align: center;
    font-size: 2.2rem;
    padding-bottom: 27px;
  }

  p {
    width: 85%;
    font-family: 'Raleway', sans-serif;
    font-size: 1.1rem;
    line-height: 1.8rem;
    margin-bottom: 1.5rem;

    b {
      font-weight: 800;
    }
  }

  @media (min-width: 600px) {
    margin-top: 0.5rem;

    h1 {
      font-size: 3rem;
    }

    p {
      font-size: 1.3rem;
    }
  }
`;

export const Sections = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.background};
  padding-top: 2rem;

  > h2 {
    color: ${({ theme }) => theme.primary};
    font-size: 2.2rem;

    @media (min-width: 600px) {
      font-size: 3rem;
    }
  }
`;

export const SectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin-top: 1rem;
  margin-bottom: 2rem;

  @media (min-width: 600px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    max-width: 970px;
    margin-bottom: 5rem;
    margin-top: 3rem;
  }

  @media (min-width: 1200px) {
    max-width: 1170px;
    justify-content: space-between;
  }
`;
