/* eslint-disable no-unused-vars */
import React from 'react';
import Article from '../../components/Article';
import FooterLeiaMais from '../../components/FooterLeiaMais';
import Header from '../../components/Header';
import ArticleHeader from '../../components/ArticleHeader';
import Paragraph from '../../components/Paragraph';
import { Content } from './styles';

const FerramentasUteis = () => {
  return (
    <>
      <Header />
      <Content>
        <Article>
          <ArticleHeader title="Links úteis" date="12 de julho de 2021" />
          <Paragraph>
            <b>
              <a
                href="https://www.adobe.com/br/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Pacote Adobe
              </a>
            </b>
            <br />
            Os softwares da Adobe são os mais utilizados pelos entrevistados,
            como Illustrator, Photoshop, After Effects e Premiere.
          </Paragraph>
          <br />
          <Paragraph>
            <b>
              <a
                href="https://www.blender.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Blender
              </a>
            </b>
            <br />
            Ferramenta gratuita para modelagens em 3D.
          </Paragraph>
          <br />{' '}
          <Paragraph>
            <b>
              <a
                href="http://ai2html.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Ai2html
              </a>
            </b>
            <br />
            Script de código aberto que transforma arquivos do Illustrator em
            HTML.
          </Paragraph>
          <br />{' '}
          <Paragraph>
            <b>
              <a
                href="https://app.flourish.studio/register"
                target="_blank"
                rel="noopener noreferrer"
              >
                Flourish
              </a>
            </b>
            <br />
            Ferramenta online para criação de gráficos.
          </Paragraph>
          <br />{' '}
          <Paragraph>
            <b>
              <a
                href="https://www.tableau.com/pt-br/trial/tableau-software?msclkid=6c99a387c12a1928c742c3679bf09bff"
                target="_blank"
                rel="noopener noreferrer"
              >
                Tableau
              </a>
            </b>
            <br />
            Ferramenta para análise e visualização de dados.
          </Paragraph>
          <br />{' '}
          <Paragraph>
            <b>
              <a
                href="https://zeplin.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Zeplin
              </a>
            </b>
            <br />
            Plataforma para publicar e compartilhar designs de interfaces com
            desenvolvedores.
          </Paragraph>
          <br />{' '}
          <Paragraph>
            <b>
              <a
                href="https://www.rstudio.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                RStudio
              </a>
            </b>
            <br />
            Software de código aberto para análise de dados.
          </Paragraph>
          <br />{' '}
          <Paragraph>
            <b>
              <a
                href="https://www.figma.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Figma
              </a>
            </b>
            <br />
            Plataforma para criar o design e protótipo de sites e aplicativos.
          </Paragraph>
          <br />{' '}
          <Paragraph>
            <b>
              <a
                href="https://observablehq.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Observable
              </a>
            </b>
            <br />
            Plataforma para compartilhar processos para visualização de dados.
          </Paragraph>
          <br />
          <Paragraph>
            <b>
              <a
                href="https://www.crehana.com/br/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Crehana
              </a>{' '}
              e{' '}
              <a href="https://www.domestika.org/" rel="noopener noreferrer">
                Domestika
              </a>
            </b>
            <br />
            Plataformas com diversos cursos voltados para a área criativa.{' '}
          </Paragraph>
          <br />
          <Paragraph>
            <b>
              <a
                href="https://color.adobe.com/pt/create/color-wheel"
                target="_blank"
                rel="noopener noreferrer"
              >
                Adobe Color
              </a>{' '}
              e{' '}
              <a
                href="https://material.io/resources/color/#!/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Color Tool
              </a>
            </b>
            <br />
            Plataformas para escolha de paleta de cores.
          </Paragraph>
          <br />
          <Paragraph>
            <b>
              <a
                href="http://tiff.herokuapp.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Tiff
              </a>
            </b>
            <br />
            Ferramenta que contrasta as diferenças entre fontes do Google Fonts.
          </Paragraph>
          <br />
        </Article>
      </Content>
      <FooterLeiaMais />
    </>
  );
};

export default FerramentasUteis;
