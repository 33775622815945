/* eslint-disable react/prop-types */
import React from 'react';
import { ArticleTitle, Meta, Date } from './styles';
import SectionTag from '../SectionTag';

const ArticleHeader = ({
  sectionTagType,
  sectionTagName,
  title,
  date,
  breakline,
}) => {
  return (
    <Meta>
      {sectionTagName && (
        <SectionTag type={sectionTagType} sectionTag={sectionTagName} />
      )}
      <ArticleTitle breakline={breakline}>{title}</ArticleTitle>
      <Date>{date}</Date>
    </Meta>
  );
};

export default ArticleHeader;
