/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Title = styled.h2`
  font-family: 'Raleway', sans-serif;
  font-size: 0.93rem;
  font-weight: 400;
  padding: 7px 21px;
  width: fit-content;
  border: solid 1px
    ${props =>
      props.type === 'default'
        ? ({ theme }) => theme.primary
        : ({ theme }) => theme.textLight};
  color: ${props =>
    props.type === 'default'
      ? ({ theme }) => theme.primary
      : ({ theme }) => theme.textLight};
  text-transform: uppercase;
`;
