/* eslint-disable no-unused-vars */
import React from 'react';
import Header from '../../components/Header';
import {
  Container,
  Content,
  Hero,
  Sections,
  SectionsContainer,
} from './styles';
import SectionBox from '../../components/SectionBox';
import Footer from '../../components/Footer';

const Home = () => {
  return (
    <>
      <Header />
      <Container>
        <Content>
          <Hero>
            <h1>Sobre o projeto</h1>
            <p>
              A publicação de um produto jornalístico depende do envolvimento de
              diferentes profissionais em seu processo. Seja em um jornal
              impresso ou em um nativo digital, o trabalho requer a participação
              não apenas de editores e repórteres, como também de designers,
              infografistas, ilustradores e diagramadores, fundamentais para
              garantir sua apresentação visual.
            </p>
            <p>
              Neste site serão apresentados os profissionais que lidam com a
              visualidade em veículos jornalísticos por meio dos seguintes
              temas: rotina de trabalho, formação, processo criativo,
              referências visuais e inspirações e a relação entre os campos do
              design e do jornalismo. Para isso, foram realizadas entrevistas
              com nove profissionais – infografistas, editores de arte,
              ilustradores e repórter visual – por meio de videoconferências
              entre os meses de fevereiro e abril de 2021.{' '}
            </p>
            <p>
              Com o propósito de ampliar as experiências apresentadas, os
              entrevistados ocupam diferentes cargos em veículos de comunicação
              com origens e perfis distintos – de nativos digitais, como{' '}
              <i>Nexo Jornal</i>, <i>UOL</i>, <i>Metrópoles</i> e{' '}
              <i>Gênero e Número</i>, a <i>Folha de S.Paulo</i>, <i>Estadão</i>{' '}
              e <i>O Globo</i>, que ainda produzem o impresso.
            </p>
            <p>
              As seções reafirmam a importância desses profissionais no fluxo de
              produção da informação, além de servirem como material de consulta
              para estudantes que têm interesse pela área e desejam trabalhar
              com a visualidade em redações jornalísticas.
            </p>
            <p>
              <b>Boa leitura!</b>
            </p>
          </Hero>
          <Sections>
            <h2>Seções</h2>
            <SectionsContainer>
              <SectionBox
                type="light"
                sectionTag="Jornalismo"
                title="Jornalismo e Design: relação de longa data"
                url="/jornalismo"
                imageLink="images/abertura/jornalismo-pessoal-da-arte_large.png"
              />
              <SectionBox
                type="light"
                sectionTag="Rotina"
                title="O dia a dia na editoria de arte (ou fora dela)"
                url="/rotina"
                imageLink="images/abertura/rotina-pessoal-da-arte_large.png"
              />
              <SectionBox
                type="light"
                sectionTag="Formação"
                title="A formação de designers que lidam com a notícia"
                url="/formacao"
                imageLink="images/abertura/formacao-pessoal-da-arte_large.png"
              />
              <SectionBox
                type="light"
                sectionTag="Processo criativo"
                title="Por trás do resultado final"
                url="/processo-criativo"
                imageLink="images/abertura/processo-criativo-pessoal-da-arte_large.png"
              />
              <SectionBox
                type="light"
                sectionTag="Projetos"
                title="Os bastidores de projetos"
                url="/projetos"
                imageLink="images/abertura/bastidores-pessoal-da-arte_large.png"
              />
              <SectionBox
                type="light"
                sectionTag="Referências e inspirações"
                title="De onde vem a inspiração"
                url="/referencias-visuais"
                imageLink="images/abertura/referencias-inspiracoes-pessoal-da-arte_large.png"
              />
              <SectionBox
                type="light"
                sectionTag="Dicas"
                title="O que você pode aprender?"
                url="/dicas"
                imageLink="images/abertura/dicas-pessoal-da-arte_large.png"
              />
            </SectionsContainer>
          </Sections>
        </Content>
      </Container>
      <Footer />
    </>
  );
};

export default Home;
