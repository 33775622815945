/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import { StyledTextBox } from './styles';

const TextBox = ({ title, children }) => {
  return (
    <StyledTextBox>
      <strong>{title}</strong>
      {children}
    </StyledTextBox>
  );
};

export default TextBox;
