import styled from 'styled-components';

export const QuoteContainer = styled.div`
  @media (min-width: 600px) {
    max-width: 595px;
    align-self: center;
  }
`;

export const OpenQuote = styled.p`
  height: 4.85rem;
  font-size: 9.375rem;
  color: ${({ theme }) => theme.primary};
  text-align: center;
`;

export const CloseQuote = styled.p`
  height: 4.85rem;
  font-size: 9.375rem;
  color: ${({ theme }) => theme.primary};
  text-align: center;
  margin-bottom: 2rem;
`;

export const Text = styled.blockquote`
  color: ${({ theme }) => theme.primary};
  font-size: 1.8rem;
  font-weight: 700;
  padding: 0.75rem 2.6rem;
  line-height: 2.3rem;
  text-align: center;

  @media (min-width: 600px) {
    font-size: 2.5rem;
    line-height: 2.8rem;
  }
`;
