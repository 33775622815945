/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  max-width: 100vw;

  @media (min-width: 970px) {
    width: 900px;
    justify-content: space-evenly;
    margin-bottom: 2rem;
  }

  @media (min-width: 1200px) {
    width: 1170px;
    justify-content: space-evenly;
  }

  span {
    font-family: 'Raleway', sans-serif;
    font-size: 1rem;
    /* padding: 0 5%; */
    width: 100%;
    max-width: 900px;

    @media (min-width: 600px) {
      font-size: 1.1rem;
    }

    @media (min-width: 1170px) {
      padding: unset;
    }
  }

  figcaption {
    font-family: 'Raleway', sans-serif;
    /* padding: 0 5%; */
    width: 100%;
    max-width: 900px;
    font-size: 1rem;

    @media (min-width: 1170px) {
      padding: unset;
    }
  }

  > div {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 10px 0;

    figure {
      margin-bottom: 10px;
    }

    figure,
    img {
      /* width: fit-content; */
      width: 100%;

      /* @media (min-width: 700px) {
        
      } */

      @media (min-width: 1170px) {
        width: 100vw;
        max-width: 1170px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    @media (min-width: 769px) {
      flex-direction: row;
      justify-content: center;
      height: auto;

      figure,
      img {
        figcaption {
          text-align: left;
          font-size: 1.1rem;
          padding-top: 1rem;
        }
      }

      figure {
        + figure {
          padding-left: 2rem;
        }
      }
    }
  }
`;
