/* eslint-disable no-unused-vars */
import React from 'react';
import Article from '../../components/Article';
import Citation from '../../components/Citation';
import FooterLeiaMais from '../../components/FooterLeiaMais';
import Header from '../../components/Header';
import OpeningImage from '../../components/Image';
import Quote from '../../components/Quote';
import ArticleHeader from '../../components/ArticleHeader';
import Paragraph from '../../components/Paragraph';
import TextBox from '../../components/TextBox';
import Footnote from '../../components/Footnote';
import { Content } from './styles';

const Dicas = () => {
  return (
    <>
      <Header />
      <OpeningImage
        imageUrl="images/abertura/dicas-pessoal-da-arte"
        alt="Colagem com fundo com cor salmão claro. Ao centro, uma mulher de pele branca segura uma lupa em frente ao seu olho direito. Ao redor dela, há um traçado com a cor azul. Quatro triângulos de cor laranja estão ao redor da mulher, dois de cada lado dela."
      />
      <Content>
        <Article>
          <ArticleHeader
            sectionTagType="default"
            sectionTagName="Dicas"
            title="O que você pode aprender?"
            date="12 de julho de 2021"
          />
          <Paragraph>
            Receber dicas de profissionais experientes costuma ser de grande
            ajuda, principalmente no início da jornada profissional. Por isso,
            aqui estão reunidas algumas recomendações feitas pelos profissionais
            – dentre elas, se manter atento às notícias e buscar novos
            conhecimentos na área por meio de leituras e cursos. Confira essas e
            outras dicas abaixo:
          </Paragraph>
          <br />
          <Paragraph>
            <strong style={{ display: 'block', marginTop: 25 }}>
              Leia e se mantenha informada(o)
            </strong>
            Trabalhar em um veículo jornalístico exige que os profissionais
            estejam bem informados. Ter interesse por jornalismo é um fator
            importante, segundo Gisele Pungan, editora de arte do <i>UOL</i>. O
            designer gráfico Guilherme Falcão aconselha a acompanhar os veículos
            de seu maior interesse, para também entender o que eles têm feito no
            campo da visualidade. “Esse olhar e essa escuta atenta para as
            pessoas, para as organizações, para como se dá o método
            jornalístico, como que uma coisa é apurada, acho que é super
            importante prestar atenção nisso tudo”, acrescenta.
          </Paragraph>
          <Paragraph>
            <strong style={{ display: 'block', marginTop: 25 }}>
              Se mantenha atualizada(o)
            </strong>
            Além de acompanhar as notícias e o que tem sido feito em outras
            redações, também é importante se manter atenta às novas ferramentas
            e tecnologias que envolvem o trabalho de design. Fabio Sales,
            editor-executivo multimídia do <i>Estadão</i>, afirma que a
            habilidade para produzir narrativas em diferentes mídias digitais é
            uma necessidade para o designer que quer atuar em redação. Já Simon
            Ducroquet, repórter visual do <i>O Globo</i>, e Gisele Pungan
            apontam para a importância em se ter familiaridade com linguagens de
            programação – mesmo que em um nível mais básico.
          </Paragraph>
          <Paragraph>
            Por outro lado, Guilherme Prímola, editor de arte do{' '}
            <i>Metrópoles</i>, lembra que qualquer habilidade técnica tem prazo
            de validade: “As coisas têm ciclos e a gente tem que estar preparado
            para se adaptar aos ciclos. Um bom designer é quem consegue se
            adaptar”.
          </Paragraph>
          <Paragraph>
            <strong style={{ display: 'block', marginTop: 25 }}>
              Mantenha contato com profissionais da área
            </strong>
            Yasmin Ayumi afirma que manter contato com uma rede de profissionais
            com quem já trabalhou é uma das melhores dicas para quem deseja se
            tornar freelancer. E isso pode ser feito ao acompanhá-los nas redes
            sociais. A designer e ilustradora freelancer também conta que é
            sempre bom mostrar o seu trabalho para esses profissionais — assim,
            aumentam as chances de receber novas propostas de trabalho.
          </Paragraph>
          <Paragraph>
            <strong style={{ display: 'block', marginTop: 25 }}>
              Peça dicas e opiniões de outros profissionais
            </strong>
            Na mesma linha da dica anterior, contatar outros profissionais para
            compartilhar ideias e pedir opinião sobre o seu trabalho é um
            caminho, segundo Catarina Pignato, infografista e ilustradora da
            <i> Folha de S.Paulo</i>. Ela acredita que muitos profissionais
            estão abertos a ouvir e ajudar. Para ela, é uma forma de entender
            que, muitas vezes, não é possível chegar à melhor solução sozinha:
            “Acreditar que nem sempre tem todas as informações, que as outras
            pessoas podem, sim, agregar muita coisa”.
          </Paragraph>
          <br />
          <Quote>
            As coisas têm ciclos e a gente tem que estar preparado para se
            adaptar aos ciclos. Um bom designer é quem consegue se adaptar.
          </Quote>
          <Paragraph>
            <strong style={{ display: 'block', marginTop: 25 }}>
              Conheça a trajetória dos profissionais da área
            </strong>
            Lucas Gomes, designer de informação do <i>Nexo Jornal</i>, lembra da
            importância de dar nome a quem está por trás de um trabalho. Ele
            fala sobre buscar esse profissional e conhecer a sua trajetória.
            Também entender que, mesmo cada pessoa tendo o seu próprio caminho –
            com diferentes privilégios que influenciam nessa trajetória –, todas
            partiram de algum lugar. Ele ainda relata a sua experiência como
            exemplo: ao trabalhar no <i>O Globo</i> e passar a ter trocas com
            profissionais experientes, ele entendeu a importância de ter acesso
            a esses profissionais.
          </Paragraph>
          <Paragraph>
            <strong style={{ display: 'block', marginTop: 25 }}>
              Produza para o seu público
            </strong>
            Criar um infográfico complexo, cheio de informações e visualmente
            atrativo parece ótimo, certo? Mas, se o seu público não entender as
            informações que estão ali, de nada adianta. Victória Sacagami,
            designer de informação da <i>Gênero e Número</i>, lembra que o
            designer sempre deve ter em mente como o público irá receber aquele
            conteúdo. O que pode parecer óbvio para um profissional que lida com
            a visualidade diariamente, nem sempre é para os leitores. “Às vezes,
            a gente pode acabar confundindo mais do que simplificando, isso para
            o design de informação é péssimo”, afirma Sacagami.
          </Paragraph>
          <Paragraph>
            <strong style={{ display: 'block', marginTop: 25 }}>
              Faça seu portfólio, mesmo que ele não seja publicado
            </strong>
            Para quem deseja atuar como repórter visual, Simon Ducroquet afirma
            que o futuro da profissão é a união das habilidades em jornalismo,
            design e programação. Se não for possível desenvolver narrativas
            visuais no ambiente profissional, ele diz que o caminho é criar esse
            conteúdo para o seu portfólio, assim pode conseguir novas
            oportunidades – até mesmo fora do país.
          </Paragraph>
          <Paragraph>
            <strong style={{ display: 'block', marginTop: 25 }}>
              Não tenha medo de errar
            </strong>
            Por fim, esse é um lembrete importante apontado por Catarina
            Pignato. Ela afirma que é comum cometer alguns erros ao lidar com
            dados e prazos apertados. Internalizar que isso pode acontecer
            impede que cada erro se torne um martírio.
          </Paragraph>
        </Article>
      </Content>
      <FooterLeiaMais />
    </>
  );
};

export default Dicas;
