import styled from 'styled-components';

export const Container = styled.header`
  background: ${({ theme }) => theme.background};
  position: sticky;
  top: 0;
  min-height: 15vh;

  display: flex;
  justify-content: center;
  z-index: 10;

  @media (min-width: 980px) {
    min-height: unset;
    height: 215px;
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;

  @media (min-width: 980px) {
    flex-direction: column-reverse;
    justify-content: flex-end;
    width: 970px;
  }

  @media (min-width: 1200px) {
    width: 1170px;
  }
`;

export const ToggleMenu = styled.div`
  width: 15px;

  @media (min-width: 980px) {
    display: none;
  }
`;

export const Title = styled.h1`
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400;
  font-size: 1.9rem;
  text-align: center;

  @media (min-width: 980px) {
    padding: 2rem 0 0 0;
    font-size: 3.5rem;
  }
`;

export const Subtitle = styled.h3`
  font-family: 'Josefin Sans', sans-serif;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 0.8rem;
  text-align: center;
  padding-top: 0.3rem;

  @media (min-width: 980px) {
    padding: 0.4rem 0 2.6rem 0;
    font-size: 1.4rem;
  }
`;

export const NavMenu = styled.nav`
  position: absolute;
  top: 100%;
  right: ${props => props.visibility};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;

  width: 100%;
  height: 85vh;
  padding: 0 2rem;

  background: ${({ theme }) => theme.background};
  transition: 0.5s;

  @media (min-width: 980px) {
    height: 25px;
    text-transform: lowercase;
    padding: 0;
    position: unset;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    :after {
      content: '';
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      bottom: 20px;
      width: 95%;
      border-top: 1px solid black;
    }
  }

  /* @media (min-width: 980px) {
    :after {
      width: 970px;
    }
  } */

  @media (min-width: 1200px) {
    :after {
      width: 1170px;
    }
  }

  a {
    font-family: 'Raleway', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    text-decoration: none;
    line-height: 3.5rem;
    color: ${({ theme }) => theme.textDark};
    position: relative;

    & + a {
      :after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        border-bottom: 1px solid black;
      }
    }

    @media (min-width: 980px) {
      padding: 0 4px;
      line-height: 1.2rem;
      text-align: center;
      width: 100%;
      font-size: 1.1rem;

      :hover {
        font-weight: 700;
      }

      & + a {
        border-left: 1px solid black;
        :after {
          position: relative;
        }
      }
    }
  }
`;
