/* eslint-disable no-unused-vars */
import React from 'react';
import { Link } from 'react-router-dom';
import Article from '../../components/Article';
import FooterLeiaMais from '../../components/FooterLeiaMais';
import Header from '../../components/Header';
import OpeningImage from '../../components/Image';
import ArticleHeader from '../../components/ArticleHeader';
import Paragraph from '../../components/Paragraph';
import ImageContainer from '../../components/ImageContainer';
import ImageContainerSpecial from '../../components/ImageContainerSpecial';
import {
  Content,
  Subtitle,
  Professional,
  // ImageContainer,
} from './styles';

const Referencias = () => {
  return (
    <>
      <Header />
      <OpeningImage
        imageUrl="images/abertura/referencias-inspiracoes-pessoal-da-arte"
        alt="Composição gráfica com fundo com cor salmão claro. Ao centro da composição, três imagens retangulares uma sobre a outra. A primeira delas, mais a frente, é uma foto da cidade de Nova York, nos Estados Unidos. Sobre a foto, há inserções gráficas feitas à mão com dados sobre profissionais da saúde que se voluntariam para ajudar a cidade de Nova York durante a pandemia da Covid-19. Há um efeito laranja sobre a foto. A segunda imagem está parcialmente coberta pela primeira, sendo possível ver apenas seu canto esquerdo e superior. É possível ver números em porcentagens e linhas. A terceira imagem está parcialmente coberta pela segunda imagem. É possível ver partes de um mapa. A imagem possui filtro na cor laranja."
      />
      <Content>
        <Article>
          <ArticleHeader
            sectionTagType="default"
            sectionTagName="Referências e inspirações"
            title="De onde vem a inspiração"
            date="12 de julho de 2021"
          />
          <Paragraph>
            Ao contrário do que se possa imaginar, uma ideia ou solução para uma
            demanda não surge apenas pelo fato de uma pessoa ser naturalmente
            criativa. A pesquisa e a busca por referências faz parte desse
            processo criativo, que é individual e, muitas vezes, estruturado e
            cheio de métodos.
          </Paragraph>

          <Paragraph>
            Os profissionais com quem conversei disseram acompanhar pessoas,
            veículos ou sites especializados que produzem e refletem sobre
            design. Para eles, o uso das redes sociais para ter acesso a esses
            conteúdos se faz cada vez mais presente. A referência, então, passa
            a ser constante, surgindo a cada deslizada pelo feed do Instagram e
            do Twitter, as principais redes citadas. São tantas inspirações
            simultâneas que o resultado para uma demanda parte de um grande
            compilado de referências.
          </Paragraph>

          <Paragraph>
            A utilização do Instagram para divulgação de trabalhos é uma
            realidade para Catarina Pignato, infografista e ilustradora da{' '}
            <i>Folha de S.Paulo</i>, e Yasmin Ayumi, designer e ilustradora
            freelancer do <i>UOL</i>. Ambas mantêm um perfil apenas com suas
            ilustrações. “Para mim, o Instagram serve mais como essa recordação
            de ‘você produz muita coisa’”, diz Pignato. Ayumi também acredita
            que a ferramenta é uma forma de se conectar e se comunicar com
            outros profissionais da área.
          </Paragraph>

          <ImageContainerSpecial>
            <div>
              <figure>
                <img
                  src="images/referencias/1-YASMIN.jpg"
                  alt="Ilustração com fundo com cor cinza. Ao centro da imagem, uma foto de uma mulher de pele branca com máscara no rosto. Ela tem um estetoscópio ao redor do pescoço. Ao lado dela, uma foto de uma mulher de pele negra com um sorriso no rosto. Ela segura um documento e tem um estetoscópio ao redor do pescoço. As duas fotos estão em preto e branco. Ao redor delas, há ilustrações de folhas e flores intercaladas com as cores rosa, roxo e laranja."
                />
              </figure>
              <figure>
                <img
                  src="images/referencias/2-CATARINA.jpg"
                  alt="Ilustração com fundo com cor cinza. Desenho de uma mulher com pele branca e os cabelos vermelhos. Ela usa um vestido branco com formas pretas e sapato vermelho. Ela segura um regador vermelho próximo a uma planta coberta de comprimidos."
                />
              </figure>
            </div>
            <span>
              <b>Esquerda</b>: ilustração de Yasmin Ayumi para o <i>UOL</i>.
              (Reprodução/Instagram) <b>Direita</b>: ilustração de Catarina
              Pignato para a <i>Folha de S.Paulo</i>. (Reprodução/Instagram){' '}
            </span>
          </ImageContainerSpecial>

          <Paragraph>
            Para Lucas Gomes, designer de informação do <i>Nexo Jornal</i>, é
            mais interessante entender o processo por trás de um resultado, como
            os prazos e as referências do profissional. “Às vezes, no Instagram
            ou no Twitter, você só vê o projeto final”.
          </Paragraph>

          <Paragraph>
            As referências, por sua vez, não partem apenas de publicações nas
            redes sociais. Os entrevistados contam que também se inspiram em
            momentos específicos e inesperados, como ao assistir a uma obra
            audiovisual, ao visitar uma exposição de arte e até mesmo ao ver um
            cartaz ou embalagem de um produto. Victória Sacagami, designer de
            informação da <i>Gênero e Número (GN)</i>, já encontrou inspiração
            em um objeto inusitado. “Eu estava procurando referência, aí
            apareceu um objeto, acho que era um <b>brinco</b>, e eu falei:
            ‘Daria uma boa visualização’”, conta.
          </Paragraph>

          <Paragraph>
            Guilherme Falcão, diretor de arte do Grupo Nexo entre 2016 e 2021,
            explica que as referências que mais o influenciam não estão
            necessariamente atreladas ao design gráfico. Suas influências vão
            desde formas orgânicas e geométricas, texturas e contrastes, à
            música, o jornalismo e a autorrepresentação. Ele conta que figuras
            ou pessoas não conformistas também aparecem no seu trabalho de
            alguma forma. “Dentro do design, o que vai me influenciar é muito
            mais sobre a maneira de pensar, de articular, sobre uma postura de
            design. Cada vez eu percebo que a minha maneira de trabalhar passa
            por aí: muito mais sobre uma atitude postural, do que sobre gostos
            tipográficos, cores, formas, um arsenal de soluções. Embora eu, como
            todo profissional, tenha isso”.
          </Paragraph>
          <Paragraph>
            Quanto às referências dentro do design, é expressivo o número de
            pessoas citadas, superior à quantidade de veículos jornalísticos ou
            sites que abordam questões relacionadas ao design. Há uma
            disparidade de gênero entre os profissionais citados como
            referência: destes, dez são mulheres e dezoito são homens.
          </Paragraph>
          <Paragraph>
            Gisele Pungan, editora de arte do <i>UOL</i>, citou pessoas e
            redações com as quais já trabalhou – como Fabio Sales,
            editor-executivo multimídia do <i>Estadão</i>, Marcelo Pliger,
            infografista colaborador da <i>Folha S.Paulo</i>, e Thea Severino,
            editora do Núcleo de Imagem do mesmo jornal. Yasmin Ayumi também
            citou antigos colegas de trabalho da Editora Abril. “São as maiores
            referências porque eu aprendi com eles diretamente”, explica. A
            infografista Catarina Pignato, que já trabalhou no{' '}
            <i>Nexo Jornal</i>, revela que ainda confere os gráficos publicados
            no veículo.
          </Paragraph>
          <Paragraph>
            O <i>Nexo Jornal</i>, inclusive, foi a publicação brasileira mais
            citada, tendo sido lembrado por Pignato, Pungan e Sacagami.
            Questionado sobre como se sentia ao saber que seu trabalho também é
            referência para outros profissionais, Lucas Gomes se mostrou
            contente com o reconhecimento e também disse sentir uma maior
            responsabilidade para continuar produzindo materiais relevantes e
            bem executados.
          </Paragraph>

          <hr
            style={{
              height: 4,
              borderWidth: 0,
              color: '#C94C32',
              backgroundColor: '#C94C32',
              width: 70,
            }}
          />
          <br />
          <Paragraph>
            <i>
              Nesta seção, as referências citadas por mais de um profissional
              têm uma breve descrição e são acompanhadas por trabalhos
              selecionados por mim. Outros nomes, veículos e sites
              especializados são apenas indicados junto a links nos quais podem
              ser encontrados.
            </i>
          </Paragraph>

          <Subtitle>Profissionais</Subtitle>

          <Paragraph>
            <Professional>Alberto Cairo</Professional>
            Jornalista e designer espanhol, Cairo tem livros publicados sobre
            visualização de dados, presta consultoria a empresas como Google e é
            professor de Jornalismo Visual na Escola de Comunicação da
            Universidade de Miami (UM). Também atuou como diretor de Infografia
            e Multimídia na Editora Globo entre 2000 e 2001. Mantém{' '}
            <a
              href="http://albertocairo.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              um site{' '}
            </a>
            sobre design da informação e visualização de dados. Em 2020, foi
            consultor do projeto da <i>Agência Lupa</i>{' '}
            <a
              href="https://piaui.folha.uol.com.br/lupa/epicentro/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i>
                E se todos os mortos por Covid‑19 no Brasil fossem seus
                vizinhos?
              </i>
            </a>
            , ganhador da medalha de bronze na categoria “formato inovador” na{' '}
            <a
              href="https://drive.google.com/file/d/1c_NuU7gtlLu7z2i61eRy8Q1YiMj5wK5R/view"
              target="_blank"
              rel="noopener noreferrer"
            >
              29ª edição do Malofiej
            </a>
            , que premia as melhores infografias e narrativas visuais do mundo.
            | Twitter{' '}
            <a
              href="https://twitter.com/AlbertoCairo"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b>@AlbertoCairo</b>
            </a>
            <br />
          </Paragraph>

          <ImageContainer>
            <div>
              <figure>
                <img
                  src="images/referencias/3-Alberto-Cairo.jpg"
                  alt="Imagem de um mapa com fundo preto. Ao centro da imagem, há um círculo no qual o mapa está mais claro que o restante da imagem. No canto inferior direito, o texto: “Vamos imaginar que todos eles viviam à sua volta: todo mundo dentro desse círculo teria morrido. É um raio de 6,1 km ao redor do seu local”."
                />
                <figcaption>
                  O projeto da Agência Lupa, que teve Alberto Cairo como
                  consultor, simula como seria se as mortes por Covid-19
                  estivessem concentradas ao redor da sua casa. A simulação
                  começa após o usuário informar o seu endereço residencial. (
                  <i>Agência Lupa</i>){' '}
                </figcaption>
              </figure>
            </div>
          </ImageContainer>

          <Paragraph>
            <Professional>Giorgia Lupi</Professional>
            Nascida na Itália, Lupi é designer de informação e trabalha no
            estúdio de design Pentagram. É co-fundadora da Accurat, empresa de
            design com foco em visualização de dados. Defende uma abordagem
            humanista segundo a qual as narrativas com dados devem se conectar
            com histórias, pessoas e ideias, não sendo apenas números, conceito
            que apresentou em seu{' '}
            <a
              href="https://www.ted.com/talks/giorgia_lupi_how_we_can_find_ourselves_in_data#t-17825"
              target="_blank"
              rel="noopener noreferrer"
            >
              TED TALK
            </a>{' '}
            com mais de um milhão de visualizações. Seu projeto{' '}
            <a
              href="http://giorgialupi.com/dear-data"
              target="_blank"
              rel="noopener noreferrer"
            >
              Dear Data
            </a>
            , feito em parceria com a designer Stefanie Posavec, faz parte da
            coleção permanente do{' '}
            <a
              href="https://www.moma.org/artists/67122?=undefined&page=&direction="
              target="_blank"
              rel="noopener noreferrer"
            >
              Museu de Arte Moderna de Nova York
            </a>
            . O projeto{' '}
            <a
              href="http://giorgialupi.com/happy-data"
              target="_blank"
              rel="noopener noreferrer"
            >
              Happy Data
            </a>
            , criado junto com colegas do estúdio Pentagram, apresenta gráficos
            com dados esperançosos diante do contexto de eventos negativos em
            2020.{' '}
            <a
              href="http://giorgialupi.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Em seu site
            </a>
            , Lupi descreve o processo por trás desse e de outros trabalhos. |
            Twitter{' '}
            <a
              href="https://twitter.com/giorgialupi"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b>@giorgialupi</b>
            </a>{' '}
            e Instagram{' '}
            <a
              href="https://www.instagram.com/giorgialupi/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b>@giorgialupi</b>
            </a>
            <br />
          </Paragraph>

          <Paragraph>
            <Professional>Mona Chalabi</Professional>
            Jornalista de dados britânica, Chalabi é editora de dados no{' '}
            <i>The Guardian US</i>. Em seu trabalho, apresenta temas como
            racismo e desigualdade de gênero na política por meio de gráficos
            desenhados à mão. Atualmente, também cobre temas{' '}
            <a
              href="https://www.theguardian.com/news/datablog/2020/dec/29/us-retail-companies-workers-coronavirus-datablog"
              target="_blank"
              rel="noopener noreferrer"
            >
              relacionados à Covid-19
            </a>
            .{' '}
            <a
              href="https://arte.folha.uol.com.br/cotidiano/2021/100-paulistanos/"
              target="_blank"
              rel="noopener noreferrer"
            >
              O especial 100 Paulistanos
            </a>
            , ilustrado por Catarina Pignato para a <i>Folha de S.Paulo</i>, se
            assemelha ao material sobre{' '}
            <a
              href="https://www.nytimes.com/interactive/2020/06/11/multimedia/coronavirus-new-york-inequality.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              nova-iorquinos{' '}
            </a>
            criado por Chalabi para o <i>New York Times</i> em junho de 2020 –
            Pignato citou a editora de dados como uma de suas referências. Em
            seu{' '}
            <a
              href="https://www.ted.com/talks/mona_chalabi_3_ways_to_spot_a_bad_statistic#t-18438"
              target="_blank"
              rel="noopener noreferrer"
            >
              TED TALK
            </a>{' '}
            com mais de dois milhões de visualizações, Chalabi explica como
            leitores podem se atentar a dados estatísticos ruins. | Twitter{' '}
            <a
              href="https://twitter.com/MonaChalabi"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b>@MonaChalabi</b>
            </a>{' '}
            e Instagram{' '}
            <a
              href="https://www.instagram.com/monachalabi/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b>@monachalabi</b>
            </a>{' '}
          </Paragraph>

          <ImageContainerSpecial>
            <div>
              <figure>
                <img
                  src="images/referencias/4-MONACHALABI.jpg"
                  alt="Gráfico de colunas ilustrado com desenhos de homens ajoelhados uns sobre os outros. Os homens simbolizam funcionários de empresas de varejo dos Estados Unidos. São cinco colunas, cada uma delas representa o lucro obtido por uma rede varejista em 2020. Na primeira posição está a Amazon, com lucro de quase 6 bilhões de dólares. A segunda colocada é a Walmart, com mais de 4 bilhões de dólares; a terceira colocada é a CVS Health, com 1 bilhão de dólares; a quarta colocada é a Home Deport, com 1 bilhão de dólares e a quinta colocada é a Lowe's, com o lucro próximo à 1 bilhão de dólares. No topo de cada pilha de funcionários há o desenho do fundador da rede varejista em questão."
                />
              </figure>
              <figure>
                <img
                  src="images/referencias/5-GIORGIA-LUPI.jpg"
                  alt="Foto da cidade de Nova York, nos Estados Unidos. Sobre a foto, há inserções gráficas feitas à mão com dados sobre profissionais da saúde que se voluntariam para ajudar a cidade de Nova York durante a pandemia da Covid-19. Centralizado no topo da imagem, o texto: “76 mil trabalhadores da saúde se voluntariaram para ajudar Nova York”."
                />
              </figure>
            </div>
            <span>
              <b>Esquerda</b>: Gráfico sobre o lucro de empresas de varejo dos
              Estados Unidos durante a pandemia da Covid-19 em 2020. (Mona
              Chalabi/<i>Guardian US</i>). <b>Direita</b>: Gráfico sobre o
              número de trabalhadores da saúde que se voluntariam na cidade de
              Nova York durante a pandemia. (Giorgia Lupi/Happy Data)
            </span>
          </ImageContainerSpecial>

          <Paragraph>
            <Professional>Ary Moraes</Professional>
            Mestre e doutor em Design pela Pontifícia Universidade Católica
            (PUC) do Rio de Janeiro. Escreveu os livros{' '}
            <a
              href="https://www.blucher.com.br/livro/detalhes/design-de-noticias-1054"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i>Design de Notícias – A Acessibilidade do Cotidiano</i>
            </a>{' '}
            e{' '}
            <a
              href="https://www.blucher.com.br/livro/detalhes/infografia-864"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i>Infografia, História e Projeto</i>
            </a>
            , ambos publicados pela editora Blucher. Atuou como editor nas
            redações do <i>O Dia</i>, <i>Correio Braziliense</i>,{' '}
            <i>Estado de Minas</i>, <i>Extra</i> e <i>Lance!</i>. É graduado em
            Desenho Industrial pela Universidade Federal do Rio de Janeiro
            (UFRJ), onde é professor, e em Jornalismo pela Universidade Estadual
            do Rio de Janeiro (Uerj). Publica seus desenhos em seu perfil do
            Instagram{' '}
            <a
              href="https://www.instagram.com/p/B96kRcyHOXR/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b>@moraesary</b>
            </a>
            .
          </Paragraph>

          <Paragraph>
            <Professional>Gabriel Gianordoli</Professional>
            Brasileiro com carreira internacional, Gianordoli recebeu o prêmio
            de{' '}
            <a
              href="https://www.snd.org/bodd/2021/03/04/2020-worlds-best-designer/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Melhor Designer do Mundo em 2020
            </a>{' '}
            pela Society of News Design (SND), na categoria de Design Digital,
            por seu trabalho como{' '}
            <a
              href="https://www.nytimes.com/by/gabriel-gianordoli"
              target="_blank"
              rel="noopener noreferrer"
            >
              editor de gráficos e multimídia no <i>The New York Times</i>
            </a>
            . No Brasil, trabalhou nas revistas <i>Superinteressante</i> e{' '}
            <i>Mundo Estranho</i>, antes de aplicar para o mestrado na Parsons
            School of Design, em Nova York. Compartilha as técnicas utilizadas
            em alguns de seus projetos na plataforma colaborativa sobre
            visualização de dados{' '}
            <a
              href="https://observablehq.com/@gianordoli"
              target="_blank"
              rel="noopener noreferrer"
            >
              Observable
            </a>
            . Na reportagem{' '}
            <a
              href="https://www.nytimes.com/interactive/2020/10/30/science/wear-mask-covid-particles-ul.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i>Masks Work. Really. We’ll Show You How</i>
            </a>
            , Gianordoli – e profissionais – mostram por meio de animações a
            efetividade do uso de máscaras para impedir a transmissão da
            Covid-19. Ao final da página, é possível ter uma experiência de
            realidade aumentada para entender como o respirador N95 filtra
            aerossóis. | Twitter{' '}
            <a
              href="https://twitter.com/gianordoli"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b>@gianordoli</b>
            </a>
          </Paragraph>

          <ImageContainer>
            <div>
              <figure>
                <img
                  src="images/referencias/6-GABRIEL.png"
                  alt="Ilustração com fundo com cor azul escuro. Do lado esquerdo, ilustrações de pessoas, homens e mulheres, usando máscaras. Há um círculo acima de uma mulher indicando que ela usa uma máscara de pano. Na sua frente, um homem usa um respirador N95. Do lado direito, imagem aproximada dos dois, onde é possível ver gotículas sendo expelidas da máscara de pano usada pela mulher."
                />
                <figcaption>
                  {' '}
                  Projeto apresenta a diferença da filtragem de máscaras de pano
                  e do respirador N95. (Gabriel Gianordoli, Or Fleisher, Yuliya
                  Parshina-Kottas, Karthik Patanjali, Miles Peyton e Bedel Saget
                  /<i>The New York Times</i>)
                </figcaption>
              </figure>
            </div>
          </ImageContainer>

          <Paragraph>
            <Professional>Marcelo Pliger</Professional>
            Professor nos cursos de Design e Jornalismo na Escola Superior de
            Propaganda e Marketing (ESPM), em São Paulo, Pliger é formado em
            Arquitetura e Urbanismo pelo Centro Universitário Belas Artes de São
            Paulo e possui mestrado em Comunicação e Semiótica pela PUC de São
            Paulo. Desde 1997, publica gráficos, mapas e infográficos na{' '}
            <i>Folha de S.Paulo</i>. | Twitter{' '}
            <a
              href="https://twitter.com/marcelopliger"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b>@marcelopliger</b>
            </a>{' '}
            e Instagram{' '}
            <a
              href="https://www.instagram.com/mpliger/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b>@mpliger</b>
            </a>
          </Paragraph>

          <ImageContainer>
            <div>
              <figure>
                <img
                  src="images/referencias/7-Marcelo-Pliger.png"
                  alt="Diagrama com fundo com cor cinza. No canto superior esquerdo, o texto: “Tive Covid entre os dias 5 de junho e 20 de junho de 2021. Este diagrama mostra os sintomas que experimentei”. Ao centro da imagem, ilustrações sequenciais da forma de um homem. Cada sintoma é sinalizado na ilustração com a cor vermelha marcando a parte do corpo afetada. No primeiro dia da doença, nenhum sintoma é marcado. No segundo e terceiro dias, há incômodo nos rins. No quarto dia, nenhum sintoma é marcado. No quinto dia, há febre ao longo da tarde. No sexto dia, há dor de cabeça, tontura e febre. No sétimo dia, há dor muscular nas costas, dor de cabeça e cansaço físico. No oitavo dia, há dor muscular nas costas, braços e tosse. No nono dia, há dor muscular nas costas, braços e pernas, febre e tosse. No décimo dia, o cansaço físico aumenta e a dor muscular está espalhada pelo corpo. Também há incômodo nos rins."
                />
                <figcaption>
                  Diagramas sobre os sintomas da Covid-19 sentidos por Pliger.
                  Foram publicados em seu perfil no Instagram e, poteriormente,
                  na <i>Folha de S.Paulo</i>. (Reprodução/Instagram)
                </figcaption>
              </figure>
            </div>
          </ImageContainer>

          <Subtitle>Veículos jornalísticos</Subtitle>

          <Paragraph>
            <Professional>
              <i>The New York Times</i>
            </Professional>
            Disparado o veículo mais citado entre os entrevistados, o{' '}
            <i>New York Times</i> aparece como uma grande referência do ponto de
            vista jornalístico. Fundado em 1851 nos Estados Unidos, o diário tem
            uma equipe com 1.700 jornalistas e, mensalmente, é lido por 150
            milhões de pessoas ao redor do mundo.
          </Paragraph>
          <Paragraph>
            É exemplo de inovação em suas reportagens multimídia, com uso de
            vídeo, infografia, recursos 3D e interatividade. Em 2021, recebeu 48
            medalhas – nove delas de ouro – na 29ª edição do prêmio Malofiej. A
            reportagem{' '}
            <a
              href="https://www.nytimes.com/interactive/2020/08/10/nyregion/nyc-subway-coronavirus.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i>What Happens to Viral Particles on the Subway</i>
            </a>
            , publicada em agosto de 2020, foi reconhecida com a medalha de
            prata na categoria digital e com a de ouro pela versão impressa.
          </Paragraph>

          <ImageContainer>
            <div>
              <figure>
                <img
                  src="images/referencias/9-NYTIMES.jpg"
                  alt="Imagem em 3D de um vagão do metrô. Ele está posicionado de lado e é possível ver o metrô por dentro. Há círculos espalhados pela imagem que simulam as partículas do vírus da Covid-19. As partículas saem pela ventilação do vagão. No canto superior esquerdo, o texto: “O fluxo contínuo de ar através dessas aberturas ajuda a limitar a formação de partículas virais dentro de um carro e a infectar as pessoas à medida que elas inalam” (tradução)."
                />
                <figcaption>
                  A narrativa visual apresenta o funcionamento do sistema de
                  ventilação do metrô de Nova York e os impactos na contaminação
                  por Covid-19. (<i>The New York Times</i>)
                </figcaption>
              </figure>
            </div>
          </ImageContainer>

          <Paragraph>
            <Professional>
              <i>The Washington Post</i>
            </Professional>
            Jornal diário norte-americano fundado em 1877. Contribuiu para
            alterar os rumos da política do país ao publicar o escândalo
            político conhecido como caso Watergate, em 1972 – que culminou na
            renúncia do então presidente Richard Nixon, em 1974.
          </Paragraph>
          <Paragraph>
            Em 2013, o jornal foi comprado por Jeff Bezos, fundador da Amazon,
            após passar 80 anos sob o comando da família Graham. Um memorando
            enviado à equipe do Post, em dezembro de 2020, revela o aumento de
            50% no número de assinantes da edição digital naquele ano. O
            crescimento motivou a contratação de mais profissionais em 2021,
            atingindo o total de 1.000 jornalistas na redação.
          </Paragraph>

          <Paragraph>
            Ganhou 12 medalhas por suas infografias na 29ª edição do Prêmio
            Malofiej, em 2021. Uma delas de ouro com a reportagem{' '}
            <a
              href="https://www.washingtonpost.com/graphics/2020/world/corona-simulator/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i>
                Why outbreaks like coronavirus spread exponentially, and how to
                flatten the curve
              </i>
            </a>
            , publicada no início da pandemia da Covid-19, em março de 2020.
          </Paragraph>

          <ImageContainer>
            <div>
              <figure style={{ maxWidth: '900px' }}>
                <img
                  style={{ maxWidth: '900px' }}
                  src="images/referencias/10-WASHINGTON-POST.jpg"
                  alt="Quatro gráficos com simulações sobre o efeito do distanciamento social para conter a transmissão da Covid-19. No primeiro gráfico, com texto “livre para todos”, a curva de pessoas doentes é superior às curvas de pessoas recuperadas e saudáveis. No segundo gráfico, com texto “tentativa de quarentena”, a curva de pessoas doentes está menor do que no primeiro gráfico, mas segue maior que as curvas de pessoas saudáveis e recuperadas. No terceiro gráfico, com texto “distanciamento moderado”, a curva de pessoas recuperadas é superior às curvas de doentes e saudáveis. No quarto gráfico, com texto “distanciamento extensivo”, a curva de pessoas recuperadas ocupa quase todo o espaço. "
                />
                <figcaption>
                  Gráficos com simulações sobre o efeito do distanciamento
                  social para conter a transmissão da Covid-19 – publicado em
                  março de 2020. A curva marrom indica pessoas doentes; a azul,
                  pessoas saudáveis; e a rosa, pessoas recuperadas. (
                  <i>The Washington Post</i>)
                </figcaption>
              </figure>
            </div>
          </ImageContainer>

          <Paragraph>
            <Professional>
              <i>The Pudding</i>
            </Professional>
            Nativo digital lançado em 2017 nos Estados Unidos. A publicação se
            dedica a narrativas visuais de forma inovadora e com liberdade para
            experimentação, o que é possível graças à falta de prazos definidos
            para cada projeto, conforme sua{' '}
            <a
              href="https://pudding.cool/about/"
              target="_blank"
              rel="noopener noreferrer"
            >
              descrição
            </a>
            . Um exemplo de sua cobertura é o projeto{' '}
            <a
              href="https://pudding.cool/2020/07/song-decay/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i>Defining the ‘90s Music Canon</i>
            </a>
            , em que são reveladas as músicas dos anos 1990 mais reconhecidas
            pelos jovens da Geração Z. O projeto faz parte da série{' '}
            <i>How Music is Remembered</i>, lançada em 2020.
          </Paragraph>
          <Paragraph>
            O <i>The Pudding</i> possui uma equipe multidisciplinar composta por
            seis pessoas, todas responsáveis pela apuração, análise de dados,
            design e desenvolvimento dos projetos. Em 2020, ganhou medalha de
            prata por seu portfólio coletivo no{' '}
            <a
              href="https://www.snd.org/bodd/2021/02/25/2020-results/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Prêmio SND: Best of Digital News Design
            </a>
            .
          </Paragraph>

          <ImageContainer>
            <div>
              <figure>
                <img
                  src="images/referencias/11-THE-PUDDING.jpg"
                  alt="Gráfico de linhas com fundo na cor rosa claro. O gráfico apresenta a porcentagem de pessoas nascidas depois dos anos 1990 que conhecem as músicas: Creep, de 1995, Too Close, de 1998, Stay, de 1994, All My Life, de 1998, You Were Meant For Me, de 1997, Tha Crossroads, de 1996. "
                />
                <figcaption>
                  Na própria página do projeto{' '}
                  <i>Defining the ‘90s Music Canon</i> é possível ouvir trechos
                  das músicas que aparecem no gráfico. (<i>The Pudding</i>)
                </figcaption>
              </figure>
            </div>
          </ImageContainer>

          <Paragraph>
            <Professional>
              <i>Nexo Jornal</i>
            </Professional>
            Criado em 2015 e sediado em São Paulo, o nativo digital produz
            narrativas utilizando diferentes recursos – gráficos, podcasts,
            vídeos, animações e interatividade. Em 2017, conquistou o Online
            Journalism Awards na categoria “excelência geral em jornalismo
            on-line – pequenas redações”, tendo sido o primeiro jornal
            brasileiro a ganhar o prêmio. Também fazem parte do Grupo Nexo o{' '}
            <a
              href="https://www.nexojornal.com.br/edu"
              target="_blank"
              rel="noopener noreferrer"
            >
              NexoEDU
            </a>
            , ferramenta com conteúdos para estudantes e professores, e a{' '}
            <a
              href="https://gamarevista.com.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Gama
            </a>
            , revista cujo foco é a cobertura de tendências, comportamento e
            cultura.
          </Paragraph>
          <ImageContainer>
            <div>
              <figure style={{ maxWidth: '900px' }}>
                <img
                  style={{ maxWidth: '900px' }}
                  src="images/referencias/12-NEXO.jpg"
                  alt="Gráfico com fundo com cor preta. Na primeira linha, há ilustrações de pessoas em fila, que representam a população mundial. Junto delas, o texto “o Brasil tem 2,7% da população mundial…”. Uma pessoa está pintada com a cor rosa, representando a porcentagem de 2,7%. Na segunda linha, há ilustrações de caixões em fila, que representam as mortes por Covid no mundo. Junto delas, o texto: “12,9% das mortes por covid-19 no mundo…”. Cinco caixões estão pintados com a cor rosa representando a porcentagem de mortes no Brasil em comparação com a população mundial. Na terceira linha, há ilustrações de pessoas em fila. Junto delas, o texto “6,0% das pessoas plenamente vacinadas no mundo.” Três pessoas estão pintadas com a cor verde, representando a quantidade da população brasileira vacinada em comparação com a população mundial."
                />
                <figcaption>
                  Gráfico que faz parte do{' '}
                  <a
                    href="https://www.nexojornal.com.br/especial/2021/06/19/500-mil-mortos-na-pandemia-por-que-poderia-ser-diferente?position=banner"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    especial sobre o que poderia ter sido feito para conter a
                    transmissão da Covid-19 no Brasil
                  </a>
                  . Publicado após o país atingir a marca de 500 mil mortos pela
                  doença. (<i>Nexo Jornal</i>)
                </figcaption>
              </figure>
            </div>
          </ImageContainer>

          <Link to="/outras-referencias">
            <Subtitle style={{ textAlign: 'center' }}>
              <u>Confira as outras referências citadas</u>
            </Subtitle>
          </Link>

          <br />
          <br />
        </Article>
      </Content>
      <FooterLeiaMais />
    </>
  );
};

export default Referencias;
