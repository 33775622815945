/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React from 'react';
import Article from '../../components/Article';
import FooterLeiaMais from '../../components/FooterLeiaMais';
import Header from '../../components/Header';
import ArticleHeader from '../../components/ArticleHeader';
import { Content, BoxContainer, BoxMiniContainer } from './styles';
import BoxReferencias from '../../components/BoxReferencias';

const OutrasReferencias = () => {
  return (
    <>
      <Header />
      <Content>
        <Article>
          <ArticleHeader
            sectionTagType="default"
            title="Outras referências"
            date="12 de julho de 2021"
          />

          <BoxContainer>
            <BoxMiniContainer>
              <BoxReferencias title="Veículos jornalísticos">
                <li>
                  <a
                    href="https://www.elmundo.es/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i>El Mundo</i>
                  </a>
                  <p>
                    Diário espanhol lançado em 1989. É o segundo maior jornal da
                    Espanha em termos de circulação e audiência. Tem publicação
                    impressa e online.
                  </p>
                </li>
                <li>
                  <a
                    href="https://elpais.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i>El País</i>
                  </a>
                  <p>
                    Criado em 1976, na Espanha, o jornal diário é líder mundial
                    de informação em espanhol. Possui redações no Brasil, México
                    e Estados Unidos .
                  </p>
                </li>{' '}
                <li>
                  <a
                    href="https://www.folha.uol.com.br/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i>Folha de S. Paulo</i>
                  </a>
                  <p>
                    Fundado em 1921, é o jornal diário de maior circulação do
                    país e líder em audiência – soma de exemplares e
                    assinaturas.
                  </p>
                </li>
                <li>
                  <a
                    href="https://www.theatlantic.com/world/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i>The Atlantic</i>
                  </a>
                  <p>
                    Revista estadunidense fundada em 1857. Sua cobertura inclui
                    análises políticas e culturais publicadas em edições
                    impressa e digital.
                  </p>
                </li>{' '}
                <li>
                  <a
                    href="https://www.ft.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i>The Financial Times</i>
                  </a>
                  <p>
                    Jornal diário lançado em 1888, no Reino Unido. Tem como foco
                    a cobertura de questões ligadas à economia e possui edições
                    impressa e digital.
                  </p>
                </li>{' '}
                <li>
                  <a
                    href="https://www.theguardian.com/international"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i>The Guardian</i>
                  </a>
                  <p>
                    Criado em 1821, no Reino Unido, o The Guardian faz parte do
                    Guardian Media Group. Publicado em plataforma digital,
                    jornal impresso e revista. Suas edições internacionais
                    incluem os Estados Unidos e a Austrália.
                  </p>
                </li>{' '}
                <li>
                  <a
                    href="https://theintercept.com/brasil/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i>The Intercept Brasil</i>
                  </a>
                  <p>
                    Agência de notícias online criada em 2016, com sede no Rio
                    de Janeiro. Foi responsável pelo caso Vaza Jato, série de
                    reportagens lançada em 2019 sobre as mensagens trocadas
                    entre integrantes da força-tarefa da Operação Lava Jato .
                  </p>
                </li>{' '}
                <li>
                  <a
                    href="https://newrepublic.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i>The New Republic</i>
                  </a>
                  <p>
                    Revista estadunidense fundada em 1914. Mantém a publicação
                    da revista impressa, com versão online disponível por
                    assinatura, e produz conteúdo para sua plataforma digital.
                  </p>
                </li>{' '}
                <li>
                  <a
                    href="https://oglobo.globo.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i>O Globo</i>
                  </a>
                  <p>
                    Criado em 1925 no Rio de Janeiro, O Globo é um jornal diário
                    publicado no formato impresso e em plataforma digital. Faz
                    parte do Grupo Globo.
                  </p>
                </li>{' '}
                <li>
                  <a
                    href="https://graphics.reuters.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i>Reuters Graphics</i>
                  </a>
                  <p>
                    Publicação digital da agência de notícias Thomson Reuters
                    dedicada à visualização de dados.
                  </p>
                </li>{' '}
                <li>
                  <a
                    href="https://www.scmp.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i>South China Morning Post</i>
                  </a>
                  <p>
                    Fundado em 1903 e sediado em Hong Kong, o jornal possui
                    edição impressa e digital com foco na cobertura sobre a
                    China e a Ásia.
                  </p>
                </li>
                <li>
                  <a
                    href="https://www.vox.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i>Vox</i>
                  </a>
                  <p>
                    Nativo digital lançado em 2014, nos Estados Unidos, cujo
                    foco é o jornalismo explicativo. É uma das marcas editoriais
                    da Vox Media.
                  </p>
                </li>
              </BoxReferencias>

              <BoxReferencias title="Projetos sobre design">
                <li>
                  <a
                    href="https://creativedoc.co/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    CreativeDoc
                  </a>
                  <p>
                    Projeto de fomento ao design brasileiro por meio de
                    entrevistas com profissionais da área.
                  </p>
                </li>
                <li>
                  <a
                    href="https://www.creativeboom.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Creative Boom
                  </a>
                  <p>
                    Revista online, fundada no Reino Unido, com produção de
                    conteúdo para profissionais da área criativa.
                  </p>
                </li>{' '}
                <li>
                  <a
                    href="https://creativemarket.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Creative Market
                  </a>
                  <p>
                    Plataforma para compra de templates, fontes, layouts, fotos
                    e vetores.
                  </p>
                </li>{' '}
                <li>
                  <a
                    href="https://datavizproject.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Data Viz Project
                  </a>
                  <p>
                    Projeto que descreve o uso mais adequado para diferentes
                    tipos de gráficos.
                  </p>
                </li>{' '}
                <li>
                  <a
                    href="https://diagrama.co/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Diagrama Podcast
                  </a>
                  <p>
                    Podcast dedicado a temas relacionados ao design, da rotina
                    dos profissionais à descrição de projetos.
                  </p>
                </li>{' '}
                <li>
                  <a
                    href="https://revistarecorte.com.br/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Revista Recorte
                  </a>
                  <p>
                    Revista brasileira digital com textos de designers e
                    não-designers sobre diferentes aspectos do design.
                  </p>
                </li>
              </BoxReferencias>

              <BoxReferencias title="Premiações">
                <li>
                  <a
                    href="https://www.malofiejgraphics.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Malofiej
                  </a>
                  <p>
                    Além de premiar as melhores infografias do mundo, o Malofiej
                    também realiza o workshop profissional “Show, Don’t Tell!” e
                    uma conferência em Pamplona (Espanha) com designers,
                    jornalistas visuais e infografistas que atuam em meios de
                    comunicação. É organizado pela SND Espanha.
                  </p>
                </li>
                <li>
                  <a
                    href="https://www.snd.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Society for News Design (SND)
                  </a>
                  <p>
                    Organização internacional formada por designers, fotógrafos,
                    infografistas, ilustradores e demais profissionais
                    responsáveis pela criação de narrativas visuais para meios
                    de comunicação. Premia profissionais e trabalhos em edições
                    anuais do The Best of News Design.
                  </p>
                </li>{' '}
                <li>
                  <a
                    href="https://www.awwwards.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    WWW Awards
                  </a>
                  <p>
                    Premiação espanhola para os melhores web designers e
                    agências do mundo.
                  </p>
                </li>{' '}
              </BoxReferencias>
            </BoxMiniContainer>

            <BoxMiniContainer>
              <BoxReferencias title="Infografistas, designers e jornalistas">
                <li>
                  <a
                    href="https://rxd.architectuur.kuleuven.be/andrew-vande-moere/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Andrew Vande Moere
                  </a>
                  <p>Professor de Design Informatics na KU Leuven, Bélgica.</p>
                </li>
                <li>
                  <a
                    href="https://twitter.com/visualisingdata"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Andy Kierk
                  </a>
                  <p>Especialista em visualização de dados.</p>
                </li>
                <li>
                  <a
                    href="https://www.tric.media/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Chico Amaral
                  </a>
                  <p>
                    Designer e jornalista, membro da TricMedia, empresa de
                    consultoria para meios de comunicação especializada em
                    transformação digital.
                  </p>
                </li>
                <li>
                  <a
                    href="https://www.flickr.com/photos/easta"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Eduardo Asta
                  </a>
                  <p>
                    Designer gráfico com passagens pela Folha de S.Paulo,
                    Estadão e UOL.
                  </p>
                </li>{' '}
                <li>
                  <a
                    href="https://www.behance.net/FedericaFragapane"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Federica Fragapane
                  </a>
                  <p>Designer de informação independente.</p>
                </li>{' '}
                <li>
                  <a
                    href="http://www.fernandaviegas.com/about"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Fernanda Viegas
                  </a>
                  <p>Pesquisadora e especialista em visualização de dados.</p>
                </li>
                <li>
                  <a
                    href="https://www.erreacomunicacion.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Javier Errea
                  </a>
                  <p>
                    Jornalista, presidente do capítulo espanhol da Society of
                    News Design, coordenador do Prêmio Malofiej e diretor do
                    estúdio Errea Comunicación.
                  </p>
                </li>{' '}
                <li>
                  <a
                    href="https://www.prodigiosovolcan.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Mario Tascón
                  </a>
                  <p>
                    Sócio fundador da Prodigioso Volcán, que presta consultoria
                    para meios de comunicação para sua transformação digital.
                  </p>
                </li>{' '}
                <li>
                  <a
                    href="https://www.behance.net/maytanferri"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    May Tanferri
                  </a>
                  <p>
                    Editora de arte sênior da Editora MOL, com passagens pela
                    Editora Globo e Editora Abril.
                  </p>
                </li>{' '}
                <li>
                  <a
                    href="https://meneg.at/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Rodrigo Menegat
                  </a>
                  <p>
                    Jornalista de dados na <i>DW News</i>, também atuou no{' '}
                    <i>Estadão</i> e <i>Folha de S.Paulo</i>.
                  </p>
                </li>{' '}
                <li>
                  <a
                    href="https://www.pecanha.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Sergio Peçanha
                  </a>
                  <p>
                    Jornalista visual no Washington Post, é autor da coluna{' '}
                    <a
                      href="https://www.washingtonpost.com/opinions/absurd-america/"
                      target="_blank"
                      rel="noopener noreferrer"
                      plainlink
                    >
                      Absurd America
                    </a>{' '}
                    publicada no jornal.
                  </p>
                </li>{' '}
                <li>
                  <a
                    href="https://sagmeister.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Stefan Sagmeister
                  </a>
                  <p>Designer gráfico austríaco.</p>
                </li>
                <li>
                  <a
                    href="http://www.stefanieposavec.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Stefanie Posavec
                  </a>
                  <p>
                    Designer de informação. Coautora do livro Dear Data feito em
                    parceria com Giorgia Lupi.
                  </p>
                </li>{' '}
                <li>
                  <a
                    href="https://tainaceccato.com/copia-de-editorial"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Tainá Ceccato
                  </a>
                  <p>
                    Designer gráfico sênior com passagem pela Editora Abril.
                  </p>
                </li>{' '}
                <li>
                  <a href="#" target="_blank" rel="noopener noreferrer">
                    Thales Molina
                  </a>
                  <p>
                    Designer gráfico e ilustrador na Fjord, estúdio de
                    consultoria de design e inovação.
                  </p>
                </li>{' '}
                <li>
                  <a
                    href="https://vsueiro.com/r"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Thea Severino
                  </a>
                  <p>
                    Designer e editora do Núcleo de Imagem da Folha de S.Paulo.
                  </p>
                </li>
                <li>
                  <a
                    href="https://vsueiro.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Vinicius Sueiro
                  </a>
                  <p>
                    Designer de informação. Foi editor assistente multimídia do{' '}
                    <i>Estadão</i>.
                  </p>
                </li>{' '}
              </BoxReferencias>

              <BoxReferencias title="Ilustradores e artistas">
                <li>
                  <a
                    href="https://crisvector.myportfolio.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Cristiano Siqueira
                  </a>
                  <p>
                    Ilustrador com portfólio dedicado a ilustrações vetoriais. |
                    Twitter{' '}
                    <a
                      href="https://twitter.com/crisvector?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                      target="_blank"
                      rel="noopener noreferrer"
                      plainlink
                    >
                      @crisvector
                    </a>
                  </p>
                </li>
                <li>
                  <a
                    href="https://jessvieira.com/home"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Jess Vieira
                  </a>
                  <p>
                    Artista visual. Suas pinturas trazem a figura feminina. |
                    Instagram{' '}
                    <a
                      href="https://www.instagram.com/jessvieira___/"
                      target="_blank"
                      rel="noopener noreferrer"
                      plainlink
                    >
                      @jessvieira_
                    </a>
                  </p>
                </li>
                <li>
                  <a
                    href="https://tab.uol.com.br/stories/o-trabalho-em-miniatura-do-projeto-quebradinha/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Marcelino Melo
                  </a>
                  <p>
                    Artista visual. Produz esculturas em miniatura sobre a
                    realidade periférica. | Instagram{' '}
                    <a
                      href="https://www.instagram.com/quebradinha_/"
                      target="_blank"
                      rel="noopener noreferrer"
                      plainlink
                    >
                      @quebradinha_
                    </a>
                  </p>
                </li>{' '}
                <li>
                  <a
                    href="https://priscilabarbosa.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Priscila Barbosa
                  </a>
                  <p>
                    Artista visual, muralista e ilustradora. | Instagram{' '}
                    <a
                      href="https://www.instagram.com/priii_barbosa/"
                      target="_blank"
                      rel="noopener noreferrer"
                      plainlink
                    >
                      @priii_barbosa
                    </a>
                  </p>
                </li>{' '}
                <li>
                  <a
                    href="http://www.speto.com.br/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Speto
                  </a>
                  <p>
                    Artista visual, muralista e ilustrador. | Instagram{' '}
                    <a
                      href="https://www.instagram.com/speto/"
                      target="_blank"
                      rel="noopener noreferrer"
                      plainlink
                    >
                      @speto
                    </a>
                  </p>
                </li>{' '}
              </BoxReferencias>
            </BoxMiniContainer>
          </BoxContainer>
          <br />
        </Article>
      </Content>
      <FooterLeiaMais />
    </>
  );
};

export default OutrasReferencias;
