/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

import { Container, Content, Title } from './styles';

const FooterLeiaMais = () => {
  return (
    <Container>
      <Title>Leia mais</Title>
      <Content>
        <Link to="/jornalismo" className="borderBottom borderRight">
          Jornalismo e Design: <br />
          relação de longa data
        </Link>
        <hr />
        <Link to="/rotina" className="borderBottom borderRight">
          O dia a dia na editoria de <br />
          arte (ou fora dela)
        </Link>
        <hr />
        <Link to="/formacao" className="borderBottom borderRight">
          A formação de designers <br />
          que lidam com a notícia
        </Link>
        <hr />
        <Link to="/processo-criativo" className="borderBottom">
          Por trás do resultado final
        </Link>
        <hr />
        <Link to="/projetos" className="borderRight">
          Os bastidores de projetos
        </Link>
        <hr />
        <Link to="/referencias-visuais" className="borderRight">
          De onde vem a inspiração
        </Link>
        <hr />
        <Link to="/dicas" className="borderRight">
          O que você pode aprender?
        </Link>
        <hr />
        <Link to="/">Voltar para a página inicial</Link>
      </Content>
    </Container>
  );
};

export default FooterLeiaMais;
