/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const ImageContainer = styled.div`
  background: ${({ theme }) => theme.background};
  text-align: center;
  height: 400px;

  picture img {
    height: 100%;
    width: 90%;
    object-fit: cover;
  }

  @media (min-width: 600px) {
    height: 100%;

    picture img {
      width: 100%;
      height: 100%;
    }
  }

  @media (min-width: 1170px) {
    picture img {
      max-width: 1170px;
    }
  }
`;
