/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Paragraph } from './styles';

const StyledParagraph = ({ children }) => {
  return <Paragraph>{children}</Paragraph>;
};

export default StyledParagraph;
