import React from 'react';
import Article from '../../components/Article';
import FooterLeiaMais from '../../components/FooterLeiaMais';
import Header from '../../components/Header';
import ArticleHeader from '../../components/ArticleHeader';
import Paragraph from '../../components/Paragraph';
import { Content, Label } from './styles';

const Entrevistadas = () => {
  return (
    <>
      <Header />
      <Content>
        <Article>
          <ArticleHeader
            title="Profissionais e veículos"
            date="12 de julho de 2021"
          />
          <Label>Profissionais</Label>
          <Paragraph>
            <b>Catarina Pignato</b> – Infografista e ilustradora da{' '}
            <i>Folha de S.Paulo</i>
            <br />
            Entrou no jornal em 2017, após participar do trainee em Jornalismo
            de Imagem. Formada em Design Gráfico na Escola Superior de
            Propaganda e Marketing (ESPM), também trabalhou como infografista e
            ilustradora no <i>Nexo Jornal</i> entre 2016 e 2017. Antes, havia
            sido estagiária em um estúdio de design.{' '}
            <a
              href="https://www.instagram.com/catarinapignato/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Clique aqui
            </a>{' '}
            para ver seu trabalho.
          </Paragraph>
          <br />
          <Paragraph>
            <b>Fabio Sales</b> – Editor-Executivo Multimídia do <i> Estadão</i>
            <br />
            Formado em Desenho Industrial na Universidade do Estado do Rio de
            Janeiro (UERJ), foi introduzido ao universo das redações em 1997, no
            jornal <i>O Dia</i>. Antes disso, havia trabalhado como programador
            visual em uma consultoria que desenvolvia sistemas financeiros para
            empresas. Atuou como editor assistente executivo no{' '}
            <i>Correio Braziliense</i> nos anos 2000, período em que o jornal
            recebeu 35 prêmios da Society of News Design (SND), o maior número
            de prêmios obtidos por um jornal brasileiro em uma única edição do
            evento.{' '}
            <a
              href="http://estadao.com.br/infograficos"
              target="_blank"
              rel="noopener noreferrer"
            >
              Clique aqui
            </a>{' '}
            para conferir seu trabalho.
          </Paragraph>
          <br />
          <Paragraph>
            <b>Gisele Pungan</b> – Editora de arte do <i>UOL</i>
            <br />
            Formada em Design pela Universidade Federal de Santa Catarina
            (UFSC), também cursou Jornalismo por dois anos na mesma instituição.
            Iniciou a carreira em redações depois de participar do programa de
            trainee da <i>Folha de S.Paulo</i>. Além de trabalhar na{' '}
            <i>Folha</i>, também passou pela Editora Abril,{' '}
            <i>Valor Econômico</i>, <i>Metro</i>, Band e Agência Estado. Desde
            2018, é editora de arte do <i>UOL</i>.{' '}
            <a
              href="https://tab.uol.com.br/edicao/rap-nordestino/#page7"
              target="_blank"
              rel="noopener noreferrer"
            >
              Clique aqui
            </a>{' '}
            para conferir seu trabalho.
          </Paragraph>
          <br />
          <Paragraph>
            <b>Guilherme Falcão</b> – Designer gráfico
            <br />
            Formado em Design Gráfico pelo SENAC e especialista em Arte: Crítica
            e Curadoria pela Pontifícia Universidade Católica de São Paulo
            (PUC/SP). Criador da CONTRA (2013-16), editora de fanzines, e
            co-fundador da Escola Livre (2014-18), projeto educacional em design
            gráfico. Foi professor convidado da Escola Britânica de Artes
            Criativas (Ebac) entre 2018 e 2019. Também atuou como diretor de
            arte do Grupo Nexo entre 2016 e 2021.{' '}
            <a
              href="https://guilhermefalcao.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Clique aqui
            </a>{' '}
            para conferir seu trabalho.
          </Paragraph>
          <br />
          <Paragraph>
            <b>Guilherme Prímola</b> – Editor de arte do <i>Metrópoles</i>
            <br />
            Formado em Publicidade pela Universidade de Brasília (UnB), está no{' '}
            <i>Metrópoles</i> desde a sua criação, em 2015. Foi coordenador de
            criação em uma agência digital, na qual desenvolveu a identidade
            visual de projetos jornalísticos, entre eles o <i>Metrópoles</i>.
            Com trabalhos publicados no portal, ganhou três vezes o ÑH – Lo
            Mejor del Diseño Periodístico e duas vezes o The Best of Digital
            Design, da Society of News Design (SND).{' '}
            <a
              href="https://www.metropoles.com/author/gui-primola"
              target="_blank"
              rel="noopener noreferrer"
            >
              Clique aqui
            </a>{' '}
            para ver seu trabalho.
          </Paragraph>
          <br />
          <Paragraph>
            <b>Lucas Gomes</b> – Designer de informação e ilustrador do{' '}
            <i>Nexo Jornal</i>
            <br />
            Formado em Comunicação Visual e Design pela Universidade Federal do
            Rio de Janeiro (UFRJ), faz parte da equipe do <i>
              Nexo Jornal
            </i>{' '}
            desde 2019. Por dois anos, trabalhou na editoria de arte da Editora
            Globo, tendo produzido para os jornais <i>O Globo</i> e <i>Extra</i>
            .{' '}
            <a
              href="https://golucas.com.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Clique aqui
            </a>{' '}
            para conferir seu trabalho.
          </Paragraph>
          <br />
          <Paragraph>
            <b>Simon Ducroquet</b> – Repórter visual do <i>O Globo</i>
            <br />
            Formado em Jornalismo pela Universidade Federal do Paraná (UFPR) e
            Design pela Universidade Tecnológica Federal do Paraná (UTFPR). Foi
            animador de infográficos na <i>Fusion</i>, uma joint-vision da
            ABC-Disney-Univision, diretor de arte do <i>Nexo Jornal</i>,
            repórter visual da <i>Folha de S.Paulo</i> e, desde novembro de
            2020, ocupa esse mesmo cargo no jornal <i>O Globo</i>. Indicado ao
            Prêmio Esso de Jornalismo em 2011 e ganhador de medalhas de bronze e
            prata no prêmio ÑH, em 2016, e de ouro no Malofiej em 2019 e 2016.{' '}
            <a
              href="https://cargocollective.com/ducroquet"
              target="_blank"
              rel="noopener noreferrer"
            >
              Clique aqui
            </a>{' '}
            para conferir seu trabalho.
          </Paragraph>
          <br />
          <Paragraph>
            <b>Victória Sacagami</b> – Designer de informação da{' '}
            <i>Gênero e Número</i>
            <br />
            Formada em Comunicação Visual e Design pela Universidade Federal do
            Rio de Janeiro (UFRJ), trabalha na <i>GN</i> desde 2020. Durante a
            graduação, produziu uma iniciação científica que resultou em uma{' '}
            <a
              href="https://www.behance.net/gallery/62210345/Visualizacao-de-dados-Parques-Publicos-Cariocas"
              target="_blank"
              rel="noopener noreferrer"
            >
              visualização dos parques públicos cariocas
            </a>
            . O projeto foi publicado na revista InfoDesign.{' '}
            <a
              href="https://www.behance.net/victoriasacagami"
              target="_blank"
              rel="noopener noreferrer"
            >
              Clique aqui
            </a>{' '}
            para conferir seu trabalho.
          </Paragraph>
          <br />
          <Paragraph>
            <b>Yasmin Ayumi</b> – Designer e ilustradora freelancer
            <br />
            Formada em Design Gráfico pelo Centro Universitário das Faculdades
            Metropolitanas Unidas (FMU), trabalhou nas revistas{' '}
            <i>Mundo Estranho</i> e <i>Superinteressante</i>, durante dois anos
            em cada. Deixou seu emprego fixo na Editora Abril, em 2019, com o
            objetivo de se estabelecer com o marido no Japão no ano seguinte,
            mas viu seus planos irem por água abaixo com a chegada da pandemia
            do coronavírus. Diante de um futuro incerto, sem saber quando
            poderia viajar para o país do qual é descendente, resolveu se tornar
            freelancer. Hoje, tem como clientes fixos o <i>UOL</i> e a
            plataforma Freepik.{' '}
            <a
              href="https://www.behance.net/yasminayumi"
              target="_blank"
              rel="noopener noreferrer"
            >
              Clique aqui
            </a>{' '}
            para conferir seu trabalho.
          </Paragraph>
          <hr
            style={{
              height: 4,
              borderWidth: 0,
              color: '#C94C32',
              backgroundColor: '#C94C32',
              width: 70,
            }}
          />
          <br />
          <Label>Veículos jornalísticos</Label>
          <Paragraph>
            <b>
              <i>Nexo Jornal</i>
            </b>{' '}
            – Criado em 2015 e sediado em São Paulo, o nativo digital tem como
            foco o jornalismo de contexto. Também produz narrativas utilizando
            diferentes recursos – gráficos, podcasts, vídeos, animações e
            interatividade. Ganhou o prêmio{' '}
            <a
              href="https://events.wan-ifra.org/events/latam-digital-media-awards-2018/content/2793"
              target="_blank"
              rel="noopener noreferrer"
            >
              LATAM Digital Media Awards
            </a>{' '}
            em 2018, na categoria “melhor site ou serviço mobile de notícias”.
            Em 2017, conquistou o{' '}
            <a
              href="https://awards.journalists.org/winners/2017/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Online Journalism Awards
            </a>{' '}
            na categoria “excelência geral em jornalismo on-line – pequenas
            redações”, tendo sido o primeiro jornal brasileiro a ganhar o
            prêmio. Atualmente, também fazem parte do Grupo Nexo o{' '}
            <a
              href="https://www.nexojornal.com.br/edu/"
              target="_blank"
              rel="noopener noreferrer"
            >
              NexoEDU
            </a>
            , ferramenta com conteúdos para estudantes e professores, e a{' '}
            <a
              href="https://gamarevista.com.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Gama
            </a>
            , revista cujo foco é a cobertura de tendências, comportamento e
            cultura.
          </Paragraph>
          <br />
          <Paragraph>
            <b>
              <i>Gênero e Número</i>
            </b>{' '}
            – Primeira organização de mídia no Brasil a aliar jornalismo a dados
            para debater questões de gênero. Criada em 2016, no Rio de Janeiro,
            a <i>GN</i> possui uma equipe enxuta composta por onze pessoas e
            mais colaboradores de tecnologia e audiovisual,{' '}
            <a
              href="http://www.generonumero.media/institucional/"
              target="_blank"
              rel="noopener noreferrer"
            >
              segundo seu site
            </a>
            . Seu modelo de negócio é baseado no subsídio de fundações,
            prestação de serviços a organizações, assinatura de newsletter e
            oferecimento de produtos editoriais. Em 2018, foi finalista do
            prêmio{' '}
            <a
              href="https://events.wan-ifra.org/events/latam-digital-media-awards-2018/content/2793"
              target="_blank"
              rel="noopener noreferrer"
            >
              LATAM Digital Media Awards
            </a>{' '}
            na categoria “melhor startup digital de notícias”.
          </Paragraph>
          <br />
          <Paragraph>
            <b>
              <i>Metrópoles</i>
            </b>{' '}
            – Criado em 2015, é líder de audiência no Distrito Federal.
            Tornou-se um portal de notícias em 2017 e, hoje, possui mais de
            trinta parceiros. Também fazem parte do grupo a Editora Metrópoles,
            com publicações de livros produzidos por jornalistas e colunistas do
            portal, e a estação Metrópoles FM (104,1). Foi reconhecido por sua
            produção jornalística em diversos prêmios, como Troféu Mulher
            Imprensa e Prêmio Jornalístico Vladimir Herzog de Anistia e Direitos
            Humanos. Todos os prêmios podem ser conferidos{' '}
            <a
              href="https://events.wan-ifra.org/events/latam-digital-media-awards-2018/content/2793"
              target="_blank"
              rel="noopener noreferrer"
            >
              aqui
            </a>
            .
          </Paragraph>
          <br />
          <Paragraph>
            <b>
              <i>Folha de S.Paulo</i>
            </b>{' '}
            – Jornal de maior circulação no país e líder em audiência (venda de
            exemplares e assinaturas), segundo o IVC Brasil (Instituto
            Verificador de Comunicação).{' '}
            <a
              href="https://www1.folha.uol.com.br/institucional/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Faz parte do Grupo Folha
            </a>
            , conglomerado composto pelo instituto de pesquisa Datafolha, o
            jornal Agora, a agência de notícias Folhapress, empresas de
            distribuição e de impressão. A Folha foi criada em 1921 e em 1995
            lançou o FolhaWeb, site de notícias em tempo real. Hoje, possui uma
            vasta produção em diferentes mídias. Dentre elas, a{' '}
            <a
              href="https://m.folha.uol.com.br/tv/"
              target="_blank"
              rel="noopener noreferrer"
            >
              TV Folha
            </a>
            , braço audiovisual criado em 2011. Mais recentemente, tem se
            destacado pelo seus programas de podcasts, em especial o{' '}
            <a
              href="https://www1.folha.uol.com.br/especial/2019/cafe-da-manha/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Café da Manhã
            </a>
            , lançado em parceria com o Spotify, em 2019.
          </Paragraph>
          <br />
          <Paragraph>
            <b>
              <i>UOL (Universo Online)</i>
            </b>{' '}
            – Primeiro serviço on-line de grande porte do Brasil, criado em
            1996, pelo Grupo Folha. Quando lançado, o site fornecia a versão
            digital e o acervo da <i>Folha de S.Paulo</i>, conteúdos dos jornais{' '}
            <i>The New York Times</i>, <i>Folha da Tarde</i> e{' '}
            <i>Notícias Populares</i> e o{' '}
            <a
              href="https://batepapo.uol.com.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              bate-papo online
            </a>{' '}
            – que segue existindo a partir de assinaturas, assim como o{' '}
            <a
              href="https://email.uol.com.br/bem-vindo?utm_source=Mail-login&utm_medium=link-assineja&utm_campaign=link-mail-login-assine-uolmail"
              target="_blank"
              rel="noopener noreferrer"
            >
              serviço de e-mail
            </a>
            . Hoje, a vertente jornalística do UOL produz seus próprios
            conteúdos, além de publicar materiais de agências de notícias
            nacionais e internacionais. Também tem sua própria produtora de
            vídeos, a MOV (antiga “TV UOL”) e o PagSeguro, sistema de pagamentos
            de compras pela internet, pertence ao site. Na{' '}
            <a
              href="https://www1.folha.uol.com.br/institucional/"
              target="_blank"
              rel="noopener noreferrer"
            >
              página sobre o Grupo Folha
            </a>
            , é afirmado que “o Grupo Folha tem participação minoritária,
            indireta e em ações sem direito a voto no UOL”.
          </Paragraph>
          <br />
          <Paragraph>
            <b>
              <i>O Estado de S. Paulo</i>
            </b>{' '}
            – Lançado em 1875, o <i>Estadão</i> é considerado o jornal mais
            antigo da cidade de São Paulo ainda em circulação. Faz parte do
            Grupo Estado, conglomerado de mídia formado por Agência Estado e
            Rádio Eldorado. Em 2019, o Estadão passou por uma reforma em direção
            à sua transformação digital – o foco da produção passou a ser as
            mídias digitais. Hoje,{' '}
            <a
              href="https://brasil.estadao.com.br/blogs/estadao-podcasts/"
              target="_blank"
              rel="noopener noreferrer"
            >
              possui programas de podcasts
            </a>
            , produzidos pela Rádio Eldorado, e produz videorreportagens com a{' '}
            <a
              href="https://tv.estadao.com.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              TV Estadão
            </a>
            . A reportagem interativa{' '}
            <a
              href="https://arte.estadao.com.br/brasil/adocao/criancas/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i>
                Simulação mostra quais crianças são adotadas (e quais não são)
                no Brasil
              </i>
            </a>{' '}
            foi vencedora no{' '}
            <a
              href="https://www.informationisbeautifulawards.com/news/540-interview-with-2019-s-best-non-english-language-winner"
              target="_blank"
              rel="noopener noreferrer"
            >
              Kantar Information is Beautiful Awards
            </a>{' '}
            em 2019, na categoria de projeto em língua não-inglesa, e também
            reconhecida no Malofiej em 2020.
          </Paragraph>
          <br />
          <Paragraph>
            <b>
              <i>O Globo</i>
            </b>{' '}
            – Criado em 1925, no Rio de Janeiro, por Irineu Marinho, pai de
            Roberto Marinho. Parte integrante do Grupo Globo, maior conglomerado
            de mídia e comunicação do Brasil e da América Latina, que reúne TV
            Globo, Globosat, Globo.com, Globo Filmes, Som Livre, entre outros.
            Na edição de 2020 do prêmio ÑH, foi reconhecido com medalha de
            bronze na categoria coberturas e breaking news no formato digital
            pela narrativa interativa em 3D sobre os{' '}
            <a
              href="https://infograficos.oglobo.globo.com/rio/coronavirus-aglomeracao-riscos.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              riscos de contágio por Covid-19 em bares.
            </a>
          </Paragraph>
          <span>
            <i>Folha de S. Paulo</i>, <i>O Globo</i>,{' '}
            <i>O Estado de S. Paulo</i> e <i>UOL</i> compõem o consórcio de
            veículos de imprensa – também formado por G1 e Extra –, criado em
            junho de 2020 para acompanhar e divulgar os números de casos e
            mortes por Covid-19 no Brasil.
          </span>
        </Article>
      </Content>
      <FooterLeiaMais />
    </>
  );
};

export default Entrevistadas;
