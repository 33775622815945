/* eslint-disable react/prop-types */
import React from 'react';
import { CloseQuote, OpenQuote, Text, QuoteContainer } from './styles';

const Quote = ({ children }) => {
  return (
    <QuoteContainer>
      <OpenQuote>&ldquo;</OpenQuote>
      <Text>{children}</Text>
      <CloseQuote>&rdquo;</CloseQuote>
    </QuoteContainer>
  );
};

export default Quote;
