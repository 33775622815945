/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';

import {
  Container,
  Content,
  NavMenu,
  Subtitle,
  Title,
  ToggleMenu,
} from './styles';

const Header = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  return (
    <Container>
      <Content>
        <ToggleMenu>
          <FontAwesomeIcon
            className="togglemenu"
            icon={isMenuVisible ? faTimes : faBars}
            onClick={() => setIsMenuVisible(!isMenuVisible)}
          />
        </ToggleMenu>
        <NavMenu visibility={isMenuVisible ? '0' : '100vw'}>
          <Link to="/profissionais" onClick={() => setIsMenuVisible(false)}>
            Profissionais e veículos
          </Link>
          <Link to="/bibliografia" onClick={() => setIsMenuVisible(false)}>
            Referências bibliográficas
          </Link>
          <Link to="/links-uteis" onClick={() => setIsMenuVisible(false)}>
            Links úteis
          </Link>
          <Link to="/agradecimentos" onClick={() => setIsMenuVisible(false)}>
            Agradecimentos
          </Link>
        </NavMenu>
        <Link to="/">
          <Title>
            o pessoal da <b>arte</b>
          </Title>
          <Subtitle>por trás do design de notícias</Subtitle>
        </Link>
        <ToggleMenu>{/* <FontAwesomeIcon icon={faSearch} /> */}</ToggleMenu>
      </Content>
    </Container>
  );
};

export default Header;
