import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.background};
`;

export const VideoContainer = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-bottom: 1rem;

  @media (min-width: 1000px) {
    width: 900px;
    margin: 2rem 0;
  }

  ::after {
    padding-top: 56.25%;
    display: block;
    content: '';
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
