/* eslint-disable no-unused-vars */
import React from 'react';
import { Link } from 'react-router-dom';
import Article from '../../components/Article';
import FooterLeiaMais from '../../components/FooterLeiaMais';
import Header from '../../components/Header';
import OpeningImage from '../../components/Image';
import ArticleHeader from '../../components/ArticleHeader';
import Paragraph from '../../components/Paragraph';
import ImageContainer from '../../components/ImageContainer';
import ImageContainerSpecial from '../../components/ImageContainerSpecial';
import { Content, VideoContainer } from './styles';

const Projetos = () => {
  return (
    <>
      <Header />
      <OpeningImage
        imageUrl="images/abertura/bastidores-pessoal-da-arte"
        alt="Composição gráfica com fundo com cor azul claro. Ao centro, três imagens recortadas que simulam papéis rasgados. Nos cantos das três imagens há ilustrações de fita adesiva. A primeira imagem é o desenho de um coração vermelho com uma seringa em frente. Sobre ela, o texto “Viva bem com vacina”. A segunda imagem é a ilustração de uma mulher de pele negra sorrindo. Ela usa roupas amarelas e o fundo da ilustração é vermelho. A terceira imagem é uma ilustração de diversas pessoas em fila. Sobre ela, o texto “Qual é o seu lugar na fila da vacina?”"
      />
      <Content>
        <Article>
          <ArticleHeader
            sectionTagType="default"
            sectionTagName="Projetos"
            title="Os bastidores de projetos"
            date="12 de julho de 2021"
          />
          <Paragraph>
            Descobrir quais foram os prazos e as motivações para a realização de
            um projeto contribui para o entendimento sobre o processo anterior
            ao seu resultado final. O tempo destinado a um projeto, assim como a
            liberdade para a sua criação, são fatores que impactam em seu
            resultado, conforme apresentado na seção{' '}
            <Link to="/processo-criativo">
              <b>Por trás do resultado final</b>
            </Link>
            .
          </Paragraph>
          <Paragraph>
            Lucas Gomes, designer de informação do <i>Nexo Jornal</i>, Simon
            Ducroquet, repórter visual do jornal <i>O Globo</i>, e Yasmin Ayumi,
            designer e ilustradora freelancer do portal <i>UOL</i>, teceram
            comentários sobre projetos específicos em que atuaram. Dois deles
            são sobre a mesma temática: a vacinação contra a Covid-19. Esses
            trabalhos serão apresentados aqui, junto de informações sobre a
            quantidade de profissionais envolvidos, seus prazos e motivações.
          </Paragraph>

          <hr
            style={{
              height: 4,
              borderWidth: 0,
              color: '#C94C32',
              backgroundColor: '#C94C32',
              width: 70,
            }}
          />

          <br />
          <Paragraph>
            O{' '}
            <a
              href="https://www.nexojornal.com.br/especial/2021/02/09/Compositoras-do-Brasil-e-do-samba"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i>especial Compositoras do Brasil e do samba</i>
            </a>
            , publicado em fevereiro de 2021 no <i>Nexo Jornal</i>, foi
            produzido pelo designer de informação Lucas Gomes e contou com a
            participação de mais três pessoas da equipe de infografia do jornal
            – para roteiro, coleta e análise de dados, desenvolvimento e edição.
            A série apresenta a vida e a obra de três compositoras: Dona Ivone
            Lara, Jovelina Pérola Negra e Leci Brandão. Com uma página para cada
            uma delas, o material ainda traz informações sobre álbuns lançados e
            dados sobre as palavras que mais aparecem em suas composições.
          </Paragraph>

          <ImageContainerSpecial>
            <div>
              <figure>
                <img
                  src="images/projetos-01.svg"
                  alt="Ilustração com fundo com cor rosa claro. Do lado esquerdo da imagem, ilustração de uma mulher com a pele negra. Ela usa um vestido rosa e segura um microfone. Ao lado dela, está escrito “Alcione”.  Do lado direito, ilustração de uma mulher com a pele negra. Ela usa um conjunto de roupa na cor verde e segura um microfone próximo a boca. Ao fundo da imagem, ilustração de pessoas tocando instrumentos."
                />
              </figure>
              <figure>
                <img
                  src="images/projetos-02.svg"
                  alt="Visualização sobre as palavras que mais aparecem
                  nas composições de Leci Brandão. (Nexo Jornal)"
                />
              </figure>
            </div>
            <span>
              <b>Esquerda</b>: Ilustrações feitas por Gomes para o especial.{' '}
              <b>Direita</b>: Visualização sobre as palavras que mais aparecem
              nas composições de Leci Brandão. (<i>Nexo Jornal</i>)
            </span>
          </ImageContainerSpecial>

          <Paragraph>
            Gomes conta que o processo – dessa e de outras demandas– começa
            considerando o prazo. A partir disso, a equipe define o que pode ser
            feito. “A gente poderia fazer uma biografia extensa delas, fazer uma
            análise mais aprofundada das letras, mas como nosso prazo eram três
            semanas, a gente definiu o escopo do trabalho pelo prazo”, afirma.
          </Paragraph>

          <Paragraph>
            Ele explica que seu processo criativo começa com suas leituras,
            filmes vistos, temas que têm sido comentados nas redes sociais e
            suas visitas a lugares. Conta que na época da produção do especial
            das compositoras do samba estava lendo um livro sobre as colagens do
            artista francês Henri Matisse e achou que o seu estilo poderia
            servir como referência para o especial. “Acho que meu processo
            criativo sempre passa por isso, coisas que eu estou vendo, que eu
            estou acompanhando e que cruzam com a demanda”.
          </Paragraph>

          <ImageContainer>
            <div>
              <figure style={{ maxWidth: '900px' }}>
                <img
                  style={{ maxWidth: '900px' }}
                  src="images/projetos-03.svg"
                  alt="Colagem feita com recortes de papel e tinta guache. No centro da imagem, a figura de uma pessoa com um violão. A colagem possui as cores verde, amarelo, rosa, preto, azul e branco."
                />
                <figcaption>
                  Henri Matisse. <i>A Tristeza do Rei</i>, 1952. Técnica mista
                  (tinta guache e colagem)
                </figcaption>
              </figure>
            </div>
          </ImageContainer>
          <br />
          <Paragraph>
            A ferramenta{' '}
            <a
              href="https://infograficos.oglobo.globo.com/sociedade/fila-vacina-coronavirus.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i>Qual é o seu lugar na fila da vacina?</i>
            </a>{' '}
            revela a informação mais aguardada pela população brasileira sobre a
            imunização contra a Covid-19. A página, publicada em dezembro de
            2020, é uma experiência interativa do leitor com o conteúdo e foi um
            dos primeiros trabalhos de Simon Ducroquet no jornal O Globo. A
            ideia para produzir essa narrativa surgiu de uma provocação do
            editor-executivo visual Alessandro Alvim, que indicou que fosse
            feito algum conteúdo sobre as vacinas contra a Covid-19 – conforme
            explicado por Ducroquet na seção{' '}
            <Link to="/processo-criativo">Por trás do resultado final</Link>, o
            editor-executivo também tem papel importante no seu processo.
          </Paragraph>

          <Paragraph>
            A partir dessa provocação, o repórter visual começou a pensar o que
            todo mundo mais queria saber naquele momento e concluiu que seria
            uma boa ideia mostrar quando as pessoas iam ser vacinadas. Logo
            depois de iniciar o projeto, o{' '}
            <a
              href="https://www.gov.br/casacivil/pt-br/assuntos/noticias/2020/dezembro/governo-federal-apresenta-o-plano-para-a-imunizacao-contra-a-covid-19"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ministério da Saúde publicou o Plano Nacional de Operacionalização
              da Vacinação
            </a>
            . Assim, pôde apresentar uma informação que passou a ser amplamente
            divulgada pela imprensa de uma forma didática e atraente. Depois de
            descobrir quando será vacinado – ou não descobrir, já que à época da
            publicação grupos não prioritários estavam sem data prevista –, o
            leitor ainda tem acesso às diferenças entre cada vacina. A apuração
            e a revisão das informações contaram com a contribuição do
            editor-executivo, de uma repórter e de uma estagiária. Ducroquet e
            Alvim também foram responsáveis pelo design e desenvolvimento da
            página que, segundo o repórter visual, levou cerca de duas semanas
            para ser publicada.
          </Paragraph>

          <ImageContainer>
            <div>
              <figure>
                <img
                  src="images/projetos-04.svg"
                  alt="Imagem com ilustrações de pessoas de frente, em fila. No topo da imagem, ao centro, o texto: “Você será vacinado depois de todos os grupos prioritários da primeira fase, sem data prevista para terminar”. Uma parte das ilustrações está pintada com a cor rosa, acompanhada do texto “Você está entre os não prioritários”. Abaixo dela, a explicação “Não prioritários – 86 milhões de pessoas. Menores de 60, que não estão em condição de vulnerabilidade, não atuam em profissões essenciais e não têm comorbidades”"
                />
                <div>
                  <figcaption>
                    O usuário deve responder algumas perguntas – clicando em
                    botões com alternativas de resposta – antes de descobrir o
                    seu lugar na fila da vacina. (<i>O Globo</i>)
                  </figcaption>
                </div>
              </figure>
            </div>
          </ImageContainer>

          <Paragraph>
            As vacinas também apareceram em uma campanha desenvolvida pelo{' '}
            <i>UOL</i>. Com o intuito de incentivar a população a se vacinar, a
            editoria de saúde e bem-estar do <i>UOL</i>, <i>VivaBem</i>,{' '}
            <a
              href="https://economia.uol.com.br/noticias/redacao/2021/03/31/vivabem-lanca-campanha-para-conscientizar-sobre-importancia-da-vacinacao.htm"
              target="_blank"
              rel="noopener noreferrer"
            >
              lançou a campanha #VivaBemComVacina
            </a>
            , em março de 2021. A designer e ilustradora Yasmin Ayumi foi a
            responsável pela criação da identidade visual, a partir das
            orientações e conceitos gráficos definidos pelos editores de arte do
            portal. A execução levou por volta de uma semana.{' '}
            <a
              href="https://www.instagram.com/explore/tags/vivabemcomvacina/"
              target="_blank"
              rel="noopener noreferrer"
            >
              A campanha segue ativa nas redes sociais
            </a>
            , com a produção de lives, posts informativos e filtros para Stories
            no Instagram. Ayumi conta que também foi pedido que fizesse a
            aplicação da logo em camisetas. Ela apresentou o projeto em nossa
            conversa por videoconferência, que pode ser conferida no vídeo
            abaixo:
          </Paragraph>
          <br />
          <VideoContainer>
            <iframe
              src="https://www.youtube.com/embed/3lXs6atRkcg"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </VideoContainer>
          <br />
        </Article>
      </Content>
      <FooterLeiaMais />
    </>
  );
};

export default Projetos;
