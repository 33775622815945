/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const StyledTextBox = styled.p`
  font-family: 'Raleway', sans-serif;
  font-size: 1rem;
  line-height: 1.875rem;
  color: ${({ theme }) => theme.primary};
  padding: 0.2rem 0 0.8rem 0;
  position: relative;

  strong {
    padding-bottom: 2px;
    display: block;
    font-style: italic;
  }

  @media (min-width: 600px) {
    max-width: 900px;
    font-size: 1.2rem;
    padding: 1rem 0 2rem 0;
  }

  @media (min-width: 800px) {
    padding-left: 20%;
  }

  a {
    text-decoration: underline;
  }
`;
