/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const StyledArticle = styled.article`
  padding: 2rem 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100vw;

  @media (min-width: 600px) {
    width: 100%;
    padding: 3rem 1rem 6rem 1rem;
  }
`;
