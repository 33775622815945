import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.background};

  span {
    font-family: 'Raleway', sans-serif;
    font-size: 1rem;
    width: 100%;
    max-width: 900px;
    padding-top: 2rem;

    @media (min-width: 600px) {
      font-size: 1.2rem;
      line-height: 2rem;
    }
  }
`;

export const Article = styled.article`
  padding: 2rem 5%;
  display: flex;
  flex-direction: column;

  @media (min-width: 600px) {
    max-width: 900px;
    padding: 4rem 0;
  }

  hr {
    width: 70px;
  }
`;

export const Label = styled.h4`
  font-size: 1.8rem;
  width: 100%;
  max-width: 900px;
  margin-bottom: 0.3rem;
`;
