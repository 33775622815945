import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.background};
`;

export const Article = styled.article`
  padding: 2rem 5%;
  display: flex;
  flex-direction: column;

  @media (min-width: 600px) {
    max-width: 900px;
    padding: 4rem 0;
  }
`;

export const Subtitle = styled.h3`
  text-align: left;
  font-size: 2rem;
  margin: 2rem 0 1rem 0;
  max-width: 900px;
  width: 100%;
`;
