/* eslint-disable no-unused-vars */
import React from 'react';
import Article from '../../components/Article';
import Citation from '../../components/Citation';
import FooterLeiaMais from '../../components/FooterLeiaMais';
import Header from '../../components/Header';
import OpeningImage from '../../components/Image';
import Quote from '../../components/Quote';
import ArticleHeader from '../../components/ArticleHeader';
import Paragraph from '../../components/Paragraph';
import TextBox from '../../components/TextBox';
import Footnote from '../../components/Footnote';
import { Content, FootnoteContainer, FootnoteLink } from './styles';

const Formacao = () => {
  return (
    <>
      <Header />
      <OpeningImage
        imageUrl="images/abertura/formacao-pessoal-da-arte"
        alt="Colagem com fundo com cor azul claro. Ao centro da imagem, há um retângulo com linhas verticais e horizontais vazadas, que simula o grid utilizado em layouts no design gráfico. Sobre as linhas, há uma imagem recortada de um computador de mesa branco, cuja tela está preenchida com ilustrações vetoriais que simulam uma página na web. Logo abaixo do computador, há uma imagem recortada de livros com títulos relacionados ao campo do design. Ao lado do computador e dos livros, há uma imagem recortada de um Ipad. Dentro dele, o desenho da letra A."
      />
      <Content>
        <Article>
          <ArticleHeader
            sectionTagType="default"
            sectionTagName="Formação"
            title="A formação de designers que lidam com a notícia"
            date="12 de julho de 2021"
            breakline
          />
          <Paragraph>
            Mais do que a educação formal, a formação de designers que atuam em
            redações é guiada pelo interesse em design, atualidades e
            jornalismo, além da constante curiosidade e busca por tudo aquilo
            que está sendo feito na área. Isso aparece nas falas dos
            profissionais entrevistados que, em sua maioria, também se mantêm
            atentos às novas (e muitas) habilidades requisitadas pela profissão.
            Para Gisele Pungan, editora de arte do <i>UOL</i>, o perfil do
            profissional conta ainda mais do que a sua formação. “Eu acho que a
            ideal talvez seja uma faculdade de Design Gráfico, mas com um
            interesse muito grande em notícia, com um certo interesse em
            tecnologia, em olhar uma página da internet e tentar imaginar como
            ela é construída, o que dá para fazer. Mas é uma coisa mais de
            perfil do que de formação, eu diria”, afirma.
          </Paragraph>
          <Paragraph>
            Gostar de desenhar foi um dos principais motivos que levaram os
            entrevistados a iniciar sua relação com o Design Gráfico. Alguns já
            tinham habilidades com softwares de edição de imagem antes mesmo de
            optar pelo curso. É o caso de Simon Ducroquet, repórter visual do{' '}
            <i>O Globo</i>, e Yasmin Ayumi, designer e ilustradora freelancer,
            que tinham o Photoshop como parceiro desde cedo. Já Catarina
            Pignato, infografista e ilustradora da <i>Folha de S.Paulo</i>, e
            Victória Sacagami, designer da <i>Gênero e Número</i>, tiveram que
            romper a barreira que tinham com o computador — hoje, o principal
            instrumento de trabalho desses profissionais.
          </Paragraph>
          <Paragraph>
            Essa adaptação de designers às tecnologias não é de hoje e vai além
            da necessidade de aprender a lidar com o computador ou um software
            de edição de imagem. Nos anos 1990, o norte-americano Victor
            Margolin, pesquisador e educador em Design, propôs mudanças no
            perfil de designers para que dialogassem com o surgimento das novas
            tecnologias de comunicação, período denominado por ele como “Idade
            da Comunicação”. Nesse novo cenário, designers deveriam deixar de
            estar às margens para passar a fazer parte do processo de
            comunicação. No caso do jornalismo, a ideia seria cumprir um papel
            de mediador da informação jornalística:
          </Paragraph>
          <Citation>
            “Planejando coberturas e edições, organizando as informações,
            desenvolvendo formas alternativas de narrar os fatos, explicando-os
            de forma clara, enfim, mapeando o mundo para pessoas comuns”
            <b>(Moraes, 2015, p.95)</b>
          </Citation>
          <Paragraph>
            Se as mudanças ocorridas durante a chamada “Idade da Comunicação” já
            exigiam a existência de designers com formação abrangente, hoje isso
            se mostra cada vez mais necessário. O crescimento das mídias
            digitais gerou novas demandas para designers que trabalham com a
            informação jornalística, como a necessidade de aprender sobre a
            jornada e experiência do usuário <span>(User Experience) </span>
            em uma página da web e a adaptação dos conteúdos para serem
            acessados em dispositivos móveis, que possuem diferentes formatos de
            tela. Por serem designers que trabalham com o digital (no caso de
            alguns, trabalho aliado ao impresso), o conhecimento em programação,
            mesmo que básico, também é entendido como importante para o
            refinamento de projetos.
          </Paragraph>
          <TextBox title="User Experience (UX)">
            Campo de atuação em que o foco é garantir a melhor experiência do
            usuário com um determinado produto ou serviço – plataformas
            digitais, aplicativos e outras ferramentas. Para isso, são feitas
            pesquisas com usuários para entender suas expectativas e formular
            soluções. O <i>User Interface (UI)</i> utiliza as soluções
            apresentadas pelo <i>UX</i> para criar interfaces que atendam às
            necessidades dos usuários.
          </TextBox>
          <Paragraph>
            Quando questionado se existe uma formação ideal para designers que
            desejam atuar em redação, Fabio Sales, editor-executivo multimídia
            do <i>Estadão</i>, afirma: “Eu acho que a formação do design acaba
            sendo sempre muito multidisciplinar e a formação do profissional
            moderno tem que ser baseada no <span>lifelong learning</span>{' '}
            (educação continuada). Ele [designer] precisa entender de
            programação, precisa saber desenhar, precisa ter domínio dos
            elementos básicos do design universal, tem que entender de layout,
            precisa entender de tipografia, tem que ter até um pouco de
            entendimento de psicologia, porque as metodologias do{' '}
            <span>design thinking</span> se utiliza muito desse campo do
            conhecimento”.
          </Paragraph>
          <TextBox title="Lifelong learning">
            Conceito no qual a educação é contínua, um aprendizado para além da
            educação formal e sem data para acabar. O aprendizado envolve
            adquirir novas habilidades, buscar cursos de extensão e
            especialização. Em{' '}
            <a
              href="https://hbr.org/2021/05/identify-and-hire-lifelong-learners"
              target="_blank"
              rel="noopener noreferrer"
            >
              artigo publicado na <i>Harvard Business Review</i>
            </a>
            , em maio de 2021, Marc Zao-Sanders afirma que a pergunta mais
            pertinente a se fazer a uma pessoa que está concorrendo a uma vaga
            de emprego é “como você aprende?”. Ele explica a importância do
            lifelong learning: &ldquo;A aprendizagem ao longo da vida é agora
            totalmente considerada como um imperativo econômico e ‘a única
            vantagem competitiva sustentável’. Os candidatos a emprego e
            funcionários que consideram, atualizam e melhoram suas habilidades
            são os de alto desempenho, especialmente a longo prazo&ldquo;.
          </TextBox>
          <TextBox title="Design thinking">
            Em tradução literal, pode ser entendido como “pensamento de design”.
            É uma abordagem que tem sido utilizada por empresas para solucionar
            problemas de forma inovadora e partindo de uma perspectiva
            colaborativa. Suas etapas envolvem ter <b>empatia</b> com o
            consumidor/usuário/interessados para entender as suas necessidades;
            <b> definir</b> o foco do projeto; <b>idear</b> as opções para
            solucionar o problema encontrado; <b>prototipar</b> o modelo de
            solução proposto e <b>implementar</b> esse modelo.
          </TextBox>

          <Paragraph>
            Com formações que variam entre Design, Publicidade, Jornalismo e
            Desenho Industrial, os profissionais se mantêm atualizados por meio
            de{' '}
            <FootnoteLink href="#formacao-nota-01" footnote>
              congressos¹
            </FootnoteLink>
            , leituras, cursos e{' '}
            <FootnoteLink href="#formacao-nota-02" footnote>
              tutoriais online²
            </FootnoteLink>{' '}
            e, até mesmo, cursos de pós-graduação, como é o caso de Guilherme
            Falcão, especialista em Arte, Crítica e Curadoria pela PUC/SP e
            antigo diretor de arte do Grupo Nexo, e Fabio Sales, que atualmente
            cursa um MBA em ciências de dados. Em 2019, Falcão revelou no
            episódio{' '}
            <a
              href="https://diagrama.co/episodio20/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i>Design, Conteúdo e Autopublicação</i>
            </a>
            , do podcast Diagrama, seu desejo de voltar a estudar formalmente.
            Em nossa conversa, quando questionado sobre isso, explicou que
            mantém o estudo de outra forma.
          </Paragraph>
          <Paragraph>
            “Eu quase comecei o processo para estudo formal. Eu acabei
            interrompendo isso também por conta da pandemia, mas coloquei essa
            energia em seguir lecionando, na verdade, e aprendendo de outra
            maneira, aprendendo através do contato com os meus alunos,
            aprendendo sobre ensinar e sobre aprender, e segui lendo,
            pesquisando, me formando indiretamente, que é uma coisa que já fiz
            durante bastante tempo”, afirma.
          </Paragraph>
          <Paragraph>
            Dentre os entrevistados, Gisele Pungan e Simon Ducroquet possuem
            formação em Jornalismo e Design. Apesar de não ter concluído o curso
            de Jornalismo, na Universidade Federal de Santa Catarina (UFSC),
            Pungan une esses dois campos no dia a dia da redação, dando suas
            sugestões quando o assunto são as informações passadas por
            jornalistas para a criação de infográficos, por exemplo. Já
            Ducroquet, inspirado no modelo consolidado em jornais do exterior,
            encontrou no design um novo jeito de contar histórias, tomando para
            si a apuração, redação, programação e planejamento visual de um
            conteúdo jornalístico – tudo isso com foco principal na web.
          </Paragraph>

          <Quote>
            Eu ouvi uma frase outro dia que eu achei muito boa: ‘não é sobre um
            perfil que se encaixa, mas é sobre um perfil que adiciona’
          </Quote>

          <Paragraph>
            Para Guilherme Prímola, editor de arte do <i>Metrópoles</i>, que
            sempre quis ser um profissional generalista, ter se formado em
            Publicidade pela Universidade de Brasília (UnB) agregou em sua
            carreira. Ainda na graduação, cursou disciplinas optativas em
            Jornalismo. “Eu sou aquele cara que faz de tudo um pouco, eu acho
            que para onde estou é uma coisa que resolve, porque você consegue
            ter uma noção de tecnologia, consegue ter uma noção de como o
            audiovisual vai entrar naquela peça digital para tomar decisões de
            formato e performance”.
          </Paragraph>
          <Paragraph>
            Já Yasmin Ayumi sentiu que, apesar de seu portfólio em design, ter
            feito apenas o curso técnico em Comunicação Visual era um limitador
            para se aprimorar na área. Decidiu cursar Design Gráfico no Centro
            Universitário das Faculdades Metropolitanas Unidas (FMU) e, depois
            de iniciar o curso, conseguiu novas oportunidades como estagiária em
            uma agência digital e, um ano mais tarde, na redação da Editora
            Abril. Hoje, tem se dedicado à carreira freelancer.
          </Paragraph>
          <Paragraph>
            Por fim, é possível perceber que apenas um curso de graduação é
            incapaz de garantir todos os conhecimentos e habilidades necessárias
            para o profissional que atua como designer, infografista ou repórter
            visual em uma redação. Tudo isso é adquirido ao longo dos anos
            profissionais, seja por meio da educação formal ou não. Mas, como
            expresso no início desta seção, o perfil do profissional é o que tem
            mais a contribuir.
          </Paragraph>
          <Paragraph>
            “Eu ouvi uma frase outro dia que eu achei muito boa: ‘não é sobre um
            perfil que se encaixa, mas é sobre um perfil que adiciona’. Então,
            acho que no fim das contas, é sobre essa predisposição a aprender, a
            compartilhar, a ouvir, a respeitar. Acho que isso, talvez, sejam as
            coisas mais importantes para um profissional de visualidade que
            queira estar atuando numa redação”, explica Falcão.
          </Paragraph>

          <FootnoteContainer>
            <Footnote id="formacao-nota-01">
              <sup>1 </sup>Foram citados o Congresso Internacional de Design da
              Informação (Cidi), Coda Br, InRio.
            </Footnote>
            <Footnote id="formacao-nota-02">
              <sup>2 </sup>Foram citadas as plataformas Crehana, Udemy e
              YouTube.
            </Footnote>
          </FootnoteContainer>
        </Article>
      </Content>
      <FooterLeiaMais />
    </>
  );
};

export default Formacao;
