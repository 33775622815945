/* eslint-disable react/prop-types */
import React from 'react';
import { StyledCitation } from './styles';

const Citation = ({ children }) => {
  return (
    <>
      <StyledCitation>{children}</StyledCitation>
    </>
  );
};

export default Citation;
