/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Container,
  Content,
  FooterLabel,
  Credits,
  TitleContainer,
  Title,
  Subtitle,
  ItemLabel,
  ItemText,
  ItemGroup,
} from './styles';

const Footer = () => {
  return (
    <Container>
      <Content>
        <FooterLabel>
          <TitleContainer>
            <Title>
              o pessoal da <b>arte</b>
            </Title>
            <Subtitle>por trás do design de notícias</Subtitle>
          </TitleContainer>
          <ItemGroup nomargin>
            <ItemLabel>Trabalho de Conclusão de Curso</ItemLabel>
            <ItemText>Jornalismo - ECA/USP</ItemText>
          </ItemGroup>
        </FooterLabel>
        <Credits>
          <ItemGroup>
            <ItemLabel>Design, artes e conteúdo</ItemLabel>
            <ItemText>Larissa Fernandes</ItemText>
          </ItemGroup>
          <ItemGroup>
            <ItemLabel>Orientação</ItemLabel>
            <ItemText>Luciano Guimarães</ItemText>
          </ItemGroup>
          <ItemGroup>
            <ItemLabel>Desenvolvimento</ItemLabel>
            <ItemText>Aline Naoe</ItemText>
          </ItemGroup>
          <ItemGroup>
            <ItemLabel>Contato</ItemLabel>
            <ItemText lowercase>
              contato.larissafernandessilva@gmail.com
            </ItemText>
          </ItemGroup>
        </Credits>
      </Content>
    </Container>
  );
};

export default Footer;
