import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.background};
`;

export const Article = styled.article`
  padding: 2rem 5%;
  display: flex;
  flex-direction: column;

  @media (min-width: 600px) {
    max-width: 900px;
    padding: 4rem 0;
  }
`;

export const BoxContainer = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  width: 100%;

  @media (min-width: 600px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    /* width: 800px; */
  }

  @media (min-width: 830px) {
    width: 900px;
    justify-content: space-evenly;
  }
`;

export const BoxMiniContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 700px) {
    width: 345px;
  }
`;
