import styled from 'styled-components';

export const Container = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.75rem 0;
  background: ${({ theme }) => theme.backgroundDark};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.textLight};
  text-align: center;
  width: 80%;

  hr {
    align-self: center;
    height: 1px;
    border-width: 0;
    background-color: #fff;
    width: 70px;

    @media (min-width: 990px) {
      display: none;
    }
  }

  @media (min-width: 990px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 980px;
  }

  @media (min-width: 1200px) {
    width: 1170px;
  }

  a {
    display: block;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.4rem;
    padding: 10px 0;

    @media (min-width: 990px) {
      line-height: 3rem;
    }

    @media (min-width: 990px) {
      font-size: 1.4rem;
      padding: 1.5rem 1rem;
      width: 200px;
      line-height: unset;

      & + a {
        :before {
          content: none;
        }
      }

      :hover {
        font-weight: 700;
      }
    }

    @media (min-width: 1200px) {
      width: 290px;
    }
  }
`;

export const Title = styled.h1`
  font: 400 1.25rem 'Raleway', sans-serif;
  color: ${({ theme }) => theme.textLight};
  text-transform: uppercase;
  padding-bottom: 2rem;
  display: inline-block;
`;
