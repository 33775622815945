import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import ScrollToTop from '../components/ScrollToTop';
import Home from '../pages/Home';
import Jornalismo from '../pages/Jornalismo';
import Rotina from '../pages/Rotina';
import Formacao from '../pages/Formacao';
import ProcessoCriativo from '../pages/ProcessoCriativo';
import Projetos from '../pages/Projetos';
import Referencias from '../pages/Referencias';
import OutrasReferencias from '../pages/OutrasReferencias';
import Dicas from '../pages/Dicas';
import Entrevistadas from '../pages/Entrevistadas';
import ReferenciasBibliograficas from '../pages/ReferenciasBibliograficas';
import FerramentasUteis from '../pages/FerramentasUteis';
import Agradecimentos from '../pages/Agradecimentos';

const Routes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/jornalismo" component={Jornalismo} exact />
        <Route path="/rotina" component={Rotina} exact />
        <Route path="/formacao" component={Formacao} exact />
        <Route path="/processo-criativo" component={ProcessoCriativo} exact />
        <Route path="/projetos" component={Projetos} exact />
        <Route path="/referencias-visuais" component={Referencias} exact />
        <Route path="/outras-referencias" component={OutrasReferencias} exact />
        <Route path="/dicas" component={Dicas} exact />
        <Route path="/profissionais" component={Entrevistadas} exact />
        <Route
          path="/bibliografia"
          component={ReferenciasBibliograficas}
          exact
        />
        <Route path="/links-uteis" component={FerramentasUteis} exact />
        <Route path="/agradecimentos" component={Agradecimentos} exact />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
