/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const StyledCitation = styled.p`
  font-family: 'Raleway', sans-serif;
  font-size: 1rem;
  border-left: 3px solid ${({ theme }) => theme.primary};
  padding-left: 1rem;
  margin: 2rem 0;
  width: 100%;
  line-height: 1.8rem;

  @media (min-width: 600px) {
    font-size: 1.2rem;
    line-height: 1.875rem;
  }

  @media (min-width: 800px) {
    margin-left: 20%;
    width: calc(800px - 20%);
    max-width: 100%;
  }

  @media (min-width: 1000px) {
    margin-left: 20%;
    width: calc(900px - 20%);
    max-width: 100%;
  }
`;
