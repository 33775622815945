/* eslint-disable no-unused-vars */
import React from 'react';
import Article from '../../components/Article';
import FooterLeiaMais from '../../components/FooterLeiaMais';
import Header from '../../components/Header';
import ArticleHeader from '../../components/ArticleHeader';
import Paragraph from '../../components/Paragraph';
import { Content } from './styles';

const Agradecimentos = () => {
  return (
    <>
      <Header />
      <Content>
        <Article>
          <ArticleHeader
            sectionTagType="default"
            title="Agradecimentos"
            date="12 de julho de 2021"
          />
          <Paragraph>
            Estudar na USP sempre pareceu um sonho distante para mim. O
            histórico da minha família não é marcado pelo acesso ao ensino
            superior. Ter conquistado a chance de ocupar esse espaço não foi
            fruto apenas do meu esforço pessoal, mas resultado de anos de
            dedicação e incentivo dos meus pais, que me proporcionaram
            diferentes oportunidades.
          </Paragraph>

          <Paragraph>
            Sempre fui encorajada por eles a me dedicar aos estudos para tentar
            garantir uma realidade diferente da que tiveram. Agradeço
            imensamente a eles, Mara e Sisenando, pelo amor e apoio
            incondicional durante esses anos. Não estaria aqui se não fosse por
            isso. Ao meu irmão, Diogo, agradeço por todo carinho e pela
            confiança depositada em mim.
          </Paragraph>
          <Paragraph>
            À Bia, amizade que levo comigo desde o ensino médio na Etec de
            Cotia, agradeço por ter sido a melhor veterana que eu poderia ter
            tido. A graduação teria sido muito mais difícil sem a sua presença.
            Sou grata por todas as risadas e conselhos trocados nos últimos anos
            e, é claro, pelo seu olhar tão valioso na leitura deste trabalho.
          </Paragraph>
          <Paragraph>
            As noites no CJE teriam sido menos divertidas sem Matheus, Mayara,
            Mirella e Lucas, amizades que a graduação me deu. Durante quatro
            anos, compartilhamos o desespero e o alívio com a entrega de
            trabalhos. Agradeço por todas as conversas e pelas idas ao Sweden e
            à lanchonete da Dona Mônica.
          </Paragraph>
          <Paragraph>
            À Marcella e à Taís, tão presentes no processo deste trabalho,
            agradeço por todas as alegrias e angústias compartilhadas. Enfrentar
            essa reta final da graduação com vocês nos uniu ainda mais. Também
            sou grata pelo período em que dividimos apartamento, junto à Bel e à
            Dani, no nosso querido 71C.
          </Paragraph>
          <Paragraph>
            À Ju, agradeço por todas as conversas em longos áudios com palavras
            repletas de muito carinho e apoio. À Aline, que foi minha editora no
            meu primeiro estágio, no Jornal da USP, agradeço por toda paciência
            e dedicação comigo e com o desenvolvimento deste site.
          </Paragraph>
          <Paragraph>
            À Marina, agradeço pelas sessões semanais de terapia que me ajudam a
            conter a ansiedade, e também por todas as dicas preciosas para o
            andamento deste trabalho.
          </Paragraph>
          <Paragraph>
            Agradeço a todas as pessoas com quem pude trabalhar, que
            contribuíram para o meu crescimento pessoal e profissional com
            conselhos e trocas enriquecedoras. Em especial, à Thais, no Jornal
            da USP, à Gabi e à Mônica, no Videocamp, e ao Bruno, na Agência
            Pública.
          </Paragraph>
          <Paragraph>
            Aos profissionais entrevistados, Catarina, Fabio, Gisele, Guilherme,
            Guilherme, Lucas, Victória, Simon e Yasmin, agradeço imensamente
            pelas conversas que permitiram a execução deste trabalho.
          </Paragraph>
          <Paragraph>
            Agradeço a todas as pessoas queridas que me apoiaram de alguma forma
            nesse processo.
          </Paragraph>
          <Paragraph>
            Por fim, ao meu orientador, Luciano, pelas orientações valiosas para
            que este trabalho ganhasse forma. Também agradeço pelas aulas da
            disciplina Técnicas Gráficas em Jornalismo, que contribuíram para o
            meu interesse por design e jornalismo visual.
          </Paragraph>
          <Paragraph>
            Estou muito grata por chegar até aqui rodeada de pessoas que me
            inspiram!
          </Paragraph>
          <br />
        </Article>
      </Content>
      <FooterLeiaMais />
    </>
  );
};

export default Agradecimentos;
