/* eslint-disable no-unused-vars */
import React from 'react';
import Article from '../../components/Article';
import Citation from '../../components/Citation';
import FooterLeiaMais from '../../components/FooterLeiaMais';
import Header from '../../components/Header';
import OpeningImage from '../../components/Image';
import Quote from '../../components/Quote';
import ArticleHeader from '../../components/ArticleHeader';
import Paragraph from '../../components/Paragraph';
import TextBox from '../../components/TextBox';
import { Content, Subtitle } from './styles';
import ImageContainer from '../../components/ImageContainer';
import ImageContainerSpecial from '../../components/ImageContainerSpecial';

const Jornalismo = () => {
  return (
    <>
      <Header />
      <OpeningImage
        imageUrl="images/abertura/jornalismo-pessoal-da-arte"
        alt="Colagem com fundo com cor laranja. Ao centro, uma mulher de pele branca está com o braço sobre uma edição antiga do jornal USA Today. Atrás do jornal há uma forma geométrica pontiaguda com a cor salmão claro."
      />
      <Content>
        <Article>
          <ArticleHeader
            sectionTagType="default"
            sectionTagName="Jornalismo"
            title="Jornalismo e Design: relação de longa data"
            date="12 de julho de 2021"
            breakline
          />
          <Paragraph>
            <b>parte 1</b>
            <br />
            Em meados do século 20, a criação do cargo de diagramador –
            profissional responsável pela hierarquização de elementos na página
            do jornal, como títulos, imagens, legendas, subtítulos e corpo de
            texto – foi um prenúncio da entrada do design no fluxo de produção
            dos jornais ocorrida no final do mesmo século. Antes da existência
            do cargo, a distribuição dos elementos nas páginas dos jornais não
            era padronizada e seguia critérios definidos por editores.
          </Paragraph>
          <Paragraph>
            Ary Moraes apresenta esse panorama histórico no livro{' '}
            <i>Design de Notícias – A Acessibilidade do Cotidiano</i>, no qual
            conceitua e caracteriza o design de notícias a partir de uma
            pesquisa acadêmica que havia desenvolvido entre 2005 e 2010. Segundo
            ele, o design de notícias envolve a “elaboração da forma da notícia
            em qualquer mídia” (MORAES, 2015, p. 141), combinando linguagem
            verbal e não-verbal. Seu advento está ligado ao contexto do
            surgimento de novas tecnologias da comunicação e informação nos anos
            1990, em que o Design passou por transformações e teve suas
            possibilidades ampliadas. O design de notícias:
          </Paragraph>
          <Citation>
            “Supõe um contexto de produção específico, organizado de modo a
            incluí-lo desde as primeiras etapas do ciclo de produção,
            favorecendo sua inserção no esforço de planejamento da referida
            produção.” <b>(MORAES, 2015, p. 114)</b>
          </Citation>
          <Paragraph>
            Por outro lado, o design de jornais, anterior ao de notícias, está
            historicamente relacionado ao jornal impresso e mais próximo da
            diagramação, voltada para a distribuição de elementos na página do
            jornal. No livro, Moraes apresenta marcos históricos relevantes para
            o surgimento do design de notícias, como a criação da Society of
            Newspapers Designers nos Estados Unidos, entre 1978 e 1979 – em
            1997, passou a se chamar Society of News Design –, e o lançamento do
            diário norte-americano <i>USA Today</i>, em 1982, jornal pioneiro ao
            incluir cores e infográficos em suas edições. A inserção gradual do
            design no fluxo de produção jornalística acompanhou as reformas
            organizacionais feitas nas redações.
          </Paragraph>
          <Citation>
            &ldquo;Todas as reformas editoriais e gráficas empreendidas nos
            jornais a partir do fim dos anos 1980 tiveram como característica o
            deslocamento do design para a fase de tomada de decisões sobre a
            produção.&ldquo; <b>(Moraes, 2015, p.127)</b>
          </Citation>
          <Subtitle>O papel do design em redações</Subtitle>
          <Paragraph>
            De maneira geral, os profissionais que trabalham com a visualidade
            em redações acreditam que o design, assim como o jornalismo, cumpre
            um papel importante de ponte entre a informação e o leitor, a partir
            do uso de recursos visuais que a tornam mais acessível. “É criar uma
            forma que potencialize a qualidade que aquele conteúdo tem”, explica
            Fabio Sales, editor-executivo multimídia do <i>Estadão</i>, jornal
            em que atua há 16 anos.
          </Paragraph>
          <Paragraph>
            Uma boa narrativa visual ou infográfico é entendida por esses
            profissionais como sendo aquela que capta a atenção do leitor e
            consegue transmitir a informação de forma clara e fluida. E “às
            vezes a maneira bonita não é a melhor maneira” de passar uma
            informação, pondera Catarina Pignato, infografista e ilustradora da{' '}
            <i>Folha de S.Paulo</i> desde 2017, ano em que participou do
            programa de trainee em Jornalismo de Imagem do jornal. O desafio é
            criar um material interessante, inovador, mas sem perder o principal
            objetivo que é informar.
          </Paragraph>
          <Paragraph>
            Já o entendimento sobre a função do designer, para além das
            redações, não é unânime. Enquanto Sales afirma que o papel do
            designer “é resolver um problema da sociedade, de uma pessoa, de um
            grupo, de uma empresa, seja ele um problema visual, um problema de
            um produto”, Guilherme Falcão, que foi diretor de arte do Grupo Nexo
            entre 2016 e 2021, discorda da função de “resolvedor de problemas”
            associada aos profissionais da área. Para ele, essa perspectiva traz
            a noção de algo desagradável do qual “você quer se livrar”. “Não é
            sobre a meta, é sobre o processo, é sobre o caminho, é sobre as
            relações, é sobre tudo isso, na verdade”, afirma Falcão.
          </Paragraph>
          <Subtitle>A permanência do impresso</Subtitle>
          <Paragraph>
            O repórter visual Simon Ducroquet iniciou a relação com a sua
            profissão ao participar do programa de trainee da{' '}
            <i>Folha de S.Paulo</i> – trajetória semelhante às de Catarina
            Pignato e Gisele Pungan, editora de arte do <i>UOL</i>. Trabalhou no
            jornal por cinco anos, antes de atuar nos nativos digitais{' '}
            <i>Fusion</i> e <i>Nexo Jornal</i>. Depois das experiências, voltou
            à <i>Folha</i> por mais quatro anos e, desde 2020, está no jornal{' '}
            <i>O Globo</i>. Sua função está mais atrelada à produção de
            narrativas digitais, mas também à criação de conteúdos para o
            impresso, o que, para ele, seria um tempo melhor aproveitado se
            focado apenas no digital. Ducroquet também faz duras críticas ao
            fluxo de produção ao qual já foi submetido: “Eu já trabalhei muitos
            anos fazendo os infográficos para edição diária do impresso. Ali
            você está limitado a uma apuração que já foi feita, às vezes a uns
            dados que não são tão interessantes, ao tempo muito curto. Acho que
            tem situações em que você acaba fazendo um trabalho muito mecânico,
            muito braçal, e acho que, criativamente, intelectualmente, é muito
            pobre. Eu até questiono se deveria existir esse tipo de produção de
            conteúdo”, diz.
          </Paragraph>
          <Paragraph>
            No caso da <i>Folha de S.Paulo</i>, a ideia de trabalho mecânico
            descrito por Ducroquet é reforçada pelo jornal ao conceder os
            créditos de autoria de um infográfico apenas quando o infografista
            participa da apuração dos dados, conforme revelado por Pignato.
            Ducroquet afirma que, apesar da dificuldade, é possível romper com
            esse padrão no qual a editoria de arte apenas presta serviços.
            “Pegando os impressos que eu conheço um pouco mais, tanto o{' '}
            <i>Estadão</i> como o <i>Globo</i> e a <i>Folha</i> têm espaço para
            isso, mas você tem que ser proativo mesmo”, afirma. Assim, possuir
            conhecimento em técnicas jornalísticas pode contribuir para que o
            designer que atua em redações participe mais ativamente do processo
            de produção jornalístico desde a fase de planejamento, e não apenas
            nas etapas de finalização.
          </Paragraph>
          <Paragraph>
            O designer de informação Lucas Gomes também aponta diferenças entre
            atuar em veículos com origens tão distintas. Ele trabalhou por dois
            anos no jornal carioca <i>O Globo</i>, antes de se mudar para São
            Paulo e integrar a equipe do <i>Nexo Jornal</i>, em 2019. Gomes
            destaca a questão das horas de trabalho, que no impresso costumam
            ser mais imprevisíveis e, muitas vezes, mais longas. Também sente
            que tem maior autonomia para propor e inovar no que diz respeito às
            narrativas visuais em um veículo nativo digital.
          </Paragraph>
          <Quote>
            Acho que tem situações em que você acaba fazendo um trabalho muito
            mecânico, muito braçal, e acho que, criativamente, intelectualmente,
            é muito pobre. Eu até questiono se deveria existir esse tipo de
            produção de conteúdo.
          </Quote>
          <Paragraph>
            Já Gisele Pungan, editora de arte do <i>UOL</i>, e Yasmin Ayumi,
            designer e ilustradora freelancer do portal, citaram como maior
            diferença as possibilidades de recursos visuais que o digital
            proporciona, que vão de vídeos a conteúdos interativos. “Eu gosto de
            cores vibrantes no meu trabalho e no digital eu consigo ter essas
            cores. No impresso, não”, conta Ayumi. Também frisam a questão do
            espaço mais delimitado no impresso, tornando as opções mais
            restritas. Ayumi diz encontrar pontos positivos no impresso, mas não
            esconde a sua preferência: “Eu acho legal porque é físico, você pode
            ir lá na banca, você folheia, vê o seu trabalho lá, mas acho que o
            digital me dá uma liberdade a mais, me possibilita outras técnicas”.
          </Paragraph>
          <Paragraph>
            A infografista e ilustradora Catarina Pignato, que ainda lida com o
            impresso diariamente, é mais ponderada: “Eu acho que é você meio que
            tentar fazer o melhor possível em todas as ocasiões e aceitar que
            uma mídia nunca vai ser igual a outra. Cada uma vai ter os seus prós
            e contras”, afirma.
          </Paragraph>
          <Subtitle>Inserção de designers no fluxo de produção</Subtitle>
          <Paragraph>
            Pungan começou a atuar em jornais depois de participar do trainee de
            jornalismo gráfico da <i>Folha de S.Paulo</i>, em 2003. Desde então,
            trabalhou para a <i>Folha</i>, <i>Editora Abril</i>,{' '}
            <i>Valor Econômico</i>, <i>Metro</i>, <i>Band</i> e{' '}
            <i>Agência Estado</i>. Quando chegou no portal <i>UOL</i>, em 2018,
            a editoria de arte não ficava no mesmo prédio que o restante da
            redação. Ela, então, buscou uma maior integração entre jornalistas e
            designers: passou a telefonar para a redação quando uma nova demanda
            não era bem compreendida, até que os próprios designers começaram a
            fazer o mesmo. As equipes, que antes nem sequer se conheciam, hoje
            ocupam o mesmo espaço – à exceção do atual cenário de pandemia
            causada pelo coronavírus, em que estão trabalhando de forma remota.
          </Paragraph>
          <Paragraph>
            Esse movimento feito por Pungan posiciona designers de forma mais
            ativa no fluxo de produção. Uma citação retirada do livro de Moraes
            aponta para isso:
          </Paragraph>
          <Citation>
            “Onde se sentam o editor de imagem ou o editor de fotografia ou o
            editor de imagem? Essa é uma discussão tremenda e é menos trivial do
            que parece porque não se trata de saber onde fica a mesa de trabalho
            dessas pessoas, mas sim qual o seu lugar no processo de produção”
            <b>(Arnold, 2000, p.37, c.2)</b>
          </Citation>
          <Paragraph>
            Ducroquet acredita que aplicar novos hábitos em veículos
            recém-lançados e nativos digitais é muito mais simples do que em
            jornais com anos de existência e que possuem uma cultura enraizada.
          </Paragraph>

          <ImageContainer>
            <div>
              <figure>
                <img
                  src="images/jornalismo/1-jornalismo.jpg"
                  alt=" Montagem com fundo com cor laranja. No centro da imagem há prints das primeiras páginas dos sites da Folha de S.Paulo, Gênero e Número  e Nexo Jornal. Atrás do print da Folha, há uma forma geométrica pontiaguda com a cor salmão claro."
                />
              </figure>
            </div>
          </ImageContainer>

          <Paragraph>
            <b>parte 2</b>
            <br />
            <i>
              Aqui, serão apresentadas discussões a respeito da produção para
              mídias digitais: de narrativas para celulares à acessibilidade dos
              conteúdos.
            </i>
          </Paragraph>
          <Subtitle>A ascensão do digital</Subtitle>
          <Paragraph>
            Fabio Sales acompanhou as transformações vividas pelo jornalismo
            brasileiro ao longo de seus 24 anos de experiência em redações. A
            mudança mais recente que vivenciou foi em 2019 com o projeto{' '}
            <b>Estadão 21</b>. Após o trabalho da consultoria espanhola{' '}
            <a
              href="https://www.prodigiosovolcan.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Prodigioso Volcán
            </a>
            , o Estadão passou por uma transformação digital, na qual a maneira
            de produzir e entregar seus conteúdos tem como objetivo adequá-los
            aos “novos canais informativos e à jornada do leitor: a informação
            tem de ir para as redes sociais, para o computador, para celulares e
            podcasts”, revela Mario Tascón, diretor-geral da Prodigioso Volcán,{' '}
            <a
              href="https://www.estadao.com.br/infograficos/economia,estadao-inicia-nova-fase-e-avanca-em-sua-transformacao-digital,1030420#:~:text=A%20inova%C3%A7%C3%A3o%20ocorre%20ap%C3%B3s%20um,Grupo%20em%20todas%20as%20plataformas."
              target="_blank"
              rel="noopener noreferrer"
            >
              em entrevista ao <i>Estadão</i>
            </a>
            . Nesse caso, é interessante observar a posição central do
            editor-executivo multimídia na organização das equipes após a
            implementação do projeto.
          </Paragraph>

          <ImageContainer>
            <div>
              <figure>
                <img
                  src="images/jornalismo/2-grafico-estadao.jpg"
                  alt="Organização da equipe do Estadão após o projeto o Estadão 21"
                />
                <figcaption>
                  Organização da equipe do <i>Estadão</i> após o projeto Estadão
                  21.{' '}
                </figcaption>
              </figure>
            </div>
          </ImageContainer>

          <Paragraph>
            Sales conta que todo o mapa de produção foi redesenhado, inclusive
            os formatos das reuniões. Hoje, a primeira reunião do dia define os
            conteúdos para os diferentes canais digitais, que são alimentados
            durante todo dia. Na dissertação de mestrado{' '}
            <i>
              O impacto das métricas de audiência na rotina de produção
              jornalística dos portais UOL e Estadão
            </i>
            , Carla Escaleira, da Escola Superior de Propaganda e Marketing
            (ESPM), afirma que a equipe de estratégias digitais do{' '}
            <i>Estadão</i> ficava localizada em um prédio diferente do restante
            da redação antes da mudança ocorrida em 2019. Atualmente, as
            métricas de audiência passaram a ter papel fundamental na produção,
            a redação inteira tem acesso a dados como a taxa de conversão para
            assinaturas por editoria e até por texto. O perfil de leitores e o
            seu comportamento também é analisado pela área de{' '}
            <i>Business Intelligence</i> do jornal paulista.
          </Paragraph>

          <Paragraph>
            De acordo com os dados do Instituto Verificador de Comunicação
            (IVC), houve crescimento no número de assinaturas digitais em 2020
            em jornais como <i>Folha de S.Paulo</i>, <i>O Globo</i> e{' '}
            <i>Estadão</i>, respectivamente em ordem de posição no ranking. As
            assinaturas digitais da <i>Folha</i>, primeira colocada,
            correspondem a 78,9% da média total de circulação do jornal, que
            soma impresso e digital.{' '}
            <a
              href="https://www.meioemensagem.com.br/home/midia/2021/02/04/grandes-jornais-tem-caminho-de-crescimento-no-digital.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Em entrevista ao <i>Meio e Mensagem</i>
            </a>
            , Marcelo Rech, presidente da Associação Nacional de Jornais (ANJ),
            fala sobre ser uma tendência manter o uso de diferentes plataformas
            e linguagens para alcançar os leitores. “A complementaridade é a
            palavra de ordem que veio para ficar, e se define pela combinação de
            conteúdos e diferentes formas de distribuição sob uma mesma marca”,
            diz Rech.
          </Paragraph>

          <Subtitle>Mudanças no perfil do jornalista</Subtitle>
          <Paragraph>
            O olhar atento para a produção em diferentes canais digitais exige
            que jornalistas não sejam apenas “seres textuais”, como aponta
            Sales. A separação entre &ldquo;pessoas de texto&ldquo; e
            &ldquo;pessoas de imagem&ldquo; também já havia sido questionada por
            Moraes em seu livro ao se referir ao novo contexto comunicacional.
          </Paragraph>
          <Paragraph>
            Assim como é recomendado que designers adquiram domínios técnicos e
            conhecimentos em diferentes áreas para a produção de narrativas
            visuais, também é cada vez mais importante que jornalistas tenham
            repertório visual para contribuir com a apresentação de notícias e
            reportagens. Pungan tem batido nessa tecla na redação do <i>UOL</i>,
            pois acredita que jornalistas devem estar abertos para trabalharem
            com formatos que vão além do texto longo, se propondo a produzir
            conteúdos de acordo com as necessidades informativas dos leitores e
            as peculiaridades de cada mídia.
          </Paragraph>
          <Paragraph>
            Em sua tese de doutorado{' '}
            <i>
              Jornalismo visual nas narrativas das grandes reportagens
              brasileiras
            </i>
            , defendida na Universidade Federal de Pernambuco (UFPE), Yara
            Medeiros dos Santos sustenta que a evolução de narrativas digitais e
            a predominância de leituras por tela de celulares geram a
            valorização da programação visual, a ponto de a nomenclatura
            jornalista visual passar a ser utilizada.
          </Paragraph>
          <Paragraph>
            Simon Ducroquet, por exemplo, se intitula repórter visual e possui
            formação em Design e Jornalismo. Partindo de sua experiência, ele
            acredita que existe certa dificuldade para que profissionais com
            esses conhecimentos passem a, de fato, fazer parte das redações
            brasileiras. Na opinião de Ducroquet, ainda que editores e diretores
            de jornais se convençam sobre a necessidade da contratação de
            profissionais com tais habilidades, também é difícil encontrar
            pessoas que tenham essa formação. Medeiros reforça essa análise, ao
            afirmar no artigo{' '}
            <i>
              Jornalismo Visual: Formas e Competências em Ascensão nos Jornais
              Impressos
            </i>
            :
          </Paragraph>
          <Citation>
            “Percebe-se que os jornalistas introjetam a importância da linguagem
            combinada do verbal com o visual em processo de aculturação lento,
            que reflete uma formação mais preocupada com o texto. Jornalistas
            que vieram a desenvolver atividades de design gráfico ou edição
            visual foram autodidatas” <b>(MEDEIROS, 2020, p.13)</b>
          </Citation>
          <Paragraph>
            Ducroquet também aponta para a distância entre modelos de produção
            consolidados em redações do exterior quando comparadas às
            brasileiras. “Tem um grande desafio cultural, de formação, de fluxo
            de produção dentro da redação para a gente poder estar dentro da
            vanguarda da produção de conteúdos visuais dentro do jornalismo”,
            afirma. O desenvolvimento lento da área no jornalismo brasileiro, em
            comparação ao internacional, também é citado por Medeiros.
          </Paragraph>
          <Subtitle>A narrativa para celulares</Subtitle>
          <Paragraph>
            Em sua tese de doutorado, Medeiros aponta que “o modo de leitura
            determina as formas de produção jornalística”. No caso do celular,
            prevalece a leitura feita de forma linear. Um{' '}
            <a
              href="https://www.nngroup.com/articles/scrolling-and-attention/"
              target="_blank"
              rel="noopener noreferrer"
            >
              estudo da Nielsen Norman Group
            </a>
            , de 2018, avaliou o comportamento de usuários em relação ao chamado{' '}
            <i>scroll</i> vertical – ou seja, aquela descida feita com o dedo em
            telas. O resultado mostrou que usuários passam cerca de 57% do seu
            tempo de visualização de páginas na parte superior da tela. Isso
            demonstra a necessidade de atrair os leitores no primeiro momento
            que acessam a página, e o uso de recursos visuais tendem a estimular
            esse <i>scroll</i>.
          </Paragraph>
          <Paragraph>
            Lucas Gomes, do <i>Nexo Jornal</i>, explica que começa a produzir o
            conteúdo a partir do formato da tela do celular para só depois
            adaptá-lo para o desktop. Assim como ele, outros profissionais
            concordam que é necessário dar uma atenção redobrada ao celular, por
            ser o principal meio de acesso às páginas. Porém, o caminho feito
            por Catarina Pignato, da <i>Folha</i>, e Victória Sacagami, designer
            de informação da <i>Gênero e Número</i> desde 2020, é inverso ao de
            Gomes e aparenta ser mais trabalhoso por terem que adaptar toda
            produção pensada para o desktop de forma que “se encaixe” na tela do
            celular. Sacagami também é responsável pela adaptação de
            infográficos para as redes sociais, o que consome muito de seu
            tempo. “Às vezes, eu produzo 25 peças sobre a mesma visualização”,
            conta. Ela explica que recentemente iniciaram o processo de entender
            quais visualizações devem ou não ser publicadas nas redes sociais,
            já que muitas peças não chegam a serem utilizadas devido à falta de
            legibilidade.
          </Paragraph>

          <ImageContainerSpecial>
            <div>
              <figure>
                <img
                  src="images/jornalismo/3-grafico-celular.png"
                  alt="Gráfico acessado pelo celular"
                />
              </figure>
              <figure>
                <img
                  src="images/jornalismo/4-grafico-desktop.png"
                  alt="Gráfico acessado no desktop. (Nexo Jornal)"
                />
              </figure>
            </div>
            <span>
              <b>Esquerda</b>: Gráfico acessado pelo celular. <b>Direita</b>:
              Gráfico acessado no desktop. <i>(Nexo Jornal)</i>
            </span>
          </ImageContainerSpecial>

          <Subtitle>A acessibilidade em narrativas digitais</Subtitle>
          <Paragraph>
            A acessibilidade na web também é fundamental para que mais pessoas
            tenham acesso ao conteúdo, mas ainda não parece ser uma questão
            consolidada na rotina das redações. A pergunta{' '}
            <b>
              “a acessibilidade dos infográficos para pessoas com deficiência é
              considerada por vocês?”
            </b>{' '}
            foi feita para Victória Sacagami, Catarina Pignato e Lucas Gomes,
            profissionais que lidam diariamente com as adaptações para
            diferentes formatos. A escolha de paleta de cores para pessoas com
            daltonismo foi a mais citada como sendo um recurso de acessibilidade
            utilizado. Gomes explica que são criadas descrições na chamada do
            texto para que infográficos possam ser melhor compreendidos e
            acessados por leitores de tela – como são chamados os programas que
            capturam o conteúdo da tela e o transformam em áudio, utilizados por
            usuários com deficiência visual. A descrição a que Gomes se refere é
            a linha-fina que resume os resultados apontados nos gráficos. Os
            dados que acompanham os infográficos são publicados em formato HTML
            pelos três veículos – na <i>GN</i>, alguns ainda são publicados como
            imagem.
          </Paragraph>

          <Paragraph>
            É positivo que os dados dos gráficos sejam disponibilizados no
            formato HTML, que permite o acesso pelo leitor de telas, mas a
            criação de narrativas não-lineares prejudica esse acesso. O teste
            feito por mim com o leitor de tela gratuito{' '}
            <a
              href="https://www.nvaccess.org/download/"
              target="_blank"
              rel="noopener noreferrer"
            >
              NVDA
            </a>{' '}
            revelou que ao entrar em contato com{' '}
            <a
              href="https://www.nexojornal.com.br/grafico/2021/07/02/Quem-tinha-plano-de-sa%C3%BAde-no-Brasil-antes-da-pandemia"
              target="_blank"
              rel="noopener noreferrer"
            >
              dados e textos distribuídos pela tela de forma não linear
            </a>
            , o programa narra informações sem que tenham um sentido lógico.
          </Paragraph>
          <Citation>
            “Especialistas em acessibilidade encorajam os web designers a
            ‘pensar em termos lineares’ sempre que possível e certificar-se de
            que as suas tabelas lidas em sequência fazem sentido”,{' '}
            <b>(LUPTON, 2006, p. 131)</b>
          </Citation>
          <Paragraph>
            O trecho do livro <i>Pensar com tipos</i>, de Ellen Lupton, reforça
            a ideia de que a própria construção da narrativa de forma linear é
            mais acessível, o que permite a inclusão não apenas de pessoas com
            deficiência mas também de idosos. Existem outros recursos que também
            devem ser utilizados, como permitir a escolha de cores e tamanho de
            fontes tipográficas, e disponibilizar a audiodescrição, recurso em
            que textos e imagens são narrados. Estes dois últimos são aplicados
            pela <i>Folha de S.Paulo</i> em seus textos, mas{' '}
            <a
              href="https://www1.folha.uol.com.br/poder/2021/01/veja-23-situacoes-em-que-bolsonaro-pode-ter-cometido-crime-de-responsabilidade.shtml"
              target="_blank"
              rel="noopener noreferrer"
            >
              infográficos
            </a>
            , que são fundamentais para o entendimento da notícia, não são
            descritos pelo recurso de audiodescrição.
          </Paragraph>
          <TextBox>
            <a
              href="https://www.ufmg.br/semanadoconhecimento/wp-content/uploads/2020/09/Tutorial-de-Audiodescri%C3%A7%C3%A3o.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Um documento de tutorial de audiodescrição
            </a>{' '}
            do Centro de Comunicação da Universidade Federal de Minas Gerais
            (UFMG) apresenta dicas de como gráficos devem ser descritos.
            “Inicialmente deve ser informado o tipo de gráfico (barras, linhas,
            setores, etc). Posteriormente, são informados os parâmetros
            apresentados nos eixos, seus respectivos valores e escalas, o
            comportamento do gráfico e/ou os dados apresentados”, afirma.
          </TextBox>
          <br />
          <Paragraph>
            A produção jornalística para meios digitais se mostra cada vez mais
            consolidada, ainda que seja acompanhada por outras formas de
            distribuição. Dados sobre o perfil e o comportamento do leitor
            passaram a fazer parte da rotina da redação, assim como a criação de
            narrativas voltadas para diferentes dispositivos. Por outro lado,
            tornar o conteúdo acessível para mais pessoas não tem sido colocado
            como prioridade. Os profissionais que trabalham com a visualidade
            têm papel importante para a mudança desse cenário ao introduzirem
            recursos acessíveis em seus trabalhos.
          </Paragraph>
          <br />
          <br />
        </Article>
      </Content>
      <FooterLeiaMais />
    </>
  );
};

export default Jornalismo;
