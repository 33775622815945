/* eslint-disable no-unused-vars */
import React from 'react';
import Article from '../../components/Article';
import Citation from '../../components/Citation';
import FooterLeiaMais from '../../components/FooterLeiaMais';
import Header from '../../components/Header';
import OpeningImage from '../../components/Image';
import Quote from '../../components/Quote';
import ArticleHeader from '../../components/ArticleHeader';
import Paragraph from '../../components/Paragraph';
import TextBox from '../../components/TextBox';
import Footnote from '../../components/Footnote';
import { Content } from './styles';

const ProcessoCriativo = () => {
  return (
    <div>
      <Header />
      <OpeningImage
        imageUrl="images/abertura/processo-criativo-pessoal-da-arte"
        alt="Ilustração com fundo com cor laranja. Há flores com cor salmão claro e conjuntos de rabiscos na cor salmão claro e na cor azul espalhados pela imagem."
      />
      <Content>
        <Article>
          <ArticleHeader
            sectionTagType="default"
            sectionTagName="Processo criativo"
            title="Por trás do resultado final"
            date="12 de julho de 2021"
          />
          <Paragraph>
            Em{' '}
            <a
              href="https://revistarecorte.com.br/artigos/o-processo-criativo-enquanto-travessia/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i>O processo criativo enquanto travessia</i>
            </a>
            , Giovani Castelucci, sócio e cofundador do estúdio de design Daó,
            revela que a criatividade não está presente apenas em profissões
            consideradas criativas. Ela também é necessária em tarefas
            cotidianas, como o ato de preparar uma refeição, que envolve a
            escolha dos ingredientes, sua combinação e preparação. Como o
            próprio título já diz, esse processo é entendido por ele como uma
            travessia, sujeita a obstáculos e imprevistos. O resultado final,
            portanto, está longe de ser conquistado em um estalar de dedos. É um
            processo que envolve escuta, pesquisa, hipóteses, referências e
            testes.
          </Paragraph>
          <Paragraph>
            As reflexões de Castelucci vão ao encontro das feitas por Guilherme
            Falcão. Ele explica que em projetos pessoais, lecionando ou enquanto
            diretor de arte do Grupo Nexo, seu processo de trabalho preza pela
            escuta. “É muito sobre compreender o contexto do qual o trabalho
            surge, para qual o trabalho responde e, a partir do entendimento
            desse contexto, tirar soluções gráficas”, diz. Nessa troca entre
            quem demanda e quem executa o trabalho, muitas perguntas são feitas
            e, para Falcão, as dicas de respostas também estão ali. Em sua
            abordagem, acredita que quanto mais se aprofundar em uma demanda,
            mais fácil é extrair insumos visuais que respondam a essas
            indagações.
          </Paragraph>
          <Paragraph>
            O designer deixou o Grupo Nexo em maio de 2021, mas afirmou que
            depois de vivenciar um processo criativo sofrido, se reconectou com
            o propósito do design aliado à comunicação jornalística, diante do
            contexto em que a informação bem apurada se faz cada vez mais
            necessária. Ter se tornado diretor de arte da <i>Revista Gama</i>,
            em 2020, também garantiu um novo frescor para esse processo. “Eu
            acho que, por um lado, expande a minha visão da relação entre
            visualidade e jornalismo e acho que também propõe uma série de
            outros desafios e elaborações e reflexões acerca da minha trajetória
            profissional”, conta.
          </Paragraph>
          <Paragraph>
            No entanto, se debruçar com atenção sobre uma demanda nem sempre é
            uma realidade possível. A imposição de prazos também exerce
            influência no andamento do processo criativo. “Eu não tenho direito
            de ter um processo de criação [risos]. Eu não tenho esse negócio de
            ‘hoje eu tive um bloqueio criativo’. Ou você entrega ou você
            entrega. Se não foi do melhor jeito possível, paciência”, explica
            Catarina Pignato, infografista e designer da <i>Folha de S.Paulo</i>
            . O fluxo intenso de produção do jornal impede que Pignato tenha
            tempo para as diferentes etapas de um processo criativo, tendo que
            recorrer a artimanhas que o encurtem. Ao se deparar com um
            infográfico que precisa ser feito, por exemplo, avalia qual é a
            melhor forma de apresentar o dado – “uma evolução ano a ano é
            [gráfico de] linha; uma evolução de categoria é [gráfico de] barra”
            – e o produz no menor tempo possível. Esse ritmo também impede uma
            análise mais aprofundada sobre a necessidade de um gráfico, pois,
            segundo a infografista, cerca de 20% da sua produção acaba não sendo
            publicada.
          </Paragraph>
          <Paragraph>
            Ainda que a falta de tempo imponha dificuldades, para Pignato é mais
            fácil lidar com demandas mais rápidas, assim ela tem um prazo
            determinado para fazer o melhor que conseguir e depois partir para
            outra. “Uma coisa que eu aprendi foi abrir mão. Fez, não deu certo?
            Tudo bem, esquece, vai para a próxima. No jornal diário a graça é
            essa”, afirma.
          </Paragraph>
          <Paragraph>
            Simon Ducroquet, repórter visual do <i>O Globo</i>, conhece bem o
            ritmo de produção diária para o jornal impresso. Baseando-se em suas
            experiências anteriores, acredita que designers que trabalham em
            redação enfrentam restrições criativas por terem que seguir o
            projeto gráfico do jornal e atender aos pedidos de outros
            profissionais. Experiência que já não vivencia mais na função que
            desempenha hoje, na qual é responsável por pensar também o conteúdo
            jornalístico. Ele explica que tem muita liberdade para propor
            narrativas visuais e, atualmente, tem se interessado por conteúdos
            interativos, feitos com programação em <span>JavaScript</span>. Ele
            utiliza o 3D em alguns de seus trabalhos e também vem se dedicando
            ao estudo de ferramentas de manipulação de dados, como Python, para
            ter mais autonomia em suas visualizações.
          </Paragraph>
          <TextBox title="JavaScript">
            Linguagem de programação que possibilita a implementação de itens
            complexos em páginas web, como mapas interativos e gráficos 2D/3D
            animados.
          </TextBox>
          <Paragraph>
            Lucas Gomes, designer de informação do <i>Nexo Jornal</i>, também
            tem papel ativo na elaboração do conteúdo. Ele afirma ter sorte no
            quesito liberdade de criação no trabalho. “Se eu tenho um tempo mais
            longo, e esse tempo pode ser três semanas ou meses, eu vou atrás de
            uma referência nova, vejo o que posso trazer de diferente, o que dá
            para inovar”, conta. Quando o tema permite, gosta de trabalhar com
            conteúdos bem-humorados.
          </Paragraph>
          <Paragraph>
            A designer de informação Victória Sacagami, da{' '}
            <i>Gênero e Número</i>, também sente que suas sugestões são bem
            recebidas pela redação. Seu processo é semelhante ao de Gomes quando
            lida com prazos maiores. Ela ilustra quando é viável e tem aprendido{' '}
            <span>Motion Design</span> para utilizar na visualização de dados.
            Gomes confirma que a liberdade para criação deve levar em
            consideração fatores como a identidade visual e o projeto gráfico do
            veículo. Sacagami também lembra que o principal objetivo, que é
            informar, não pode ser prejudicado durante esse processo de
            experimentação.
          </Paragraph>
          <TextBox title="Motion Design">
            Ramificação do Design Gráfico que utiliza recursos visuais e
            elementos gráficos em animações 2D e 3D. A técnica é empregada em
            infográficos, vinhetas, gifs para as redes sociais, logos animados,
            entre outros.
          </TextBox>
          <Paragraph>
            Já Ducroquet afirma que seu processo de trabalho tem dois caminhos
            possíveis: a pauta pode partir dele ou ele pode ser instigado pelo
            editor a produzir uma narrativa sobre algum tema em evidência. Nos
            dois casos, o processo dialoga com o que foi relatado pelos outros
            profissionais: ele faz pesquisas para entender mais sobre o assunto,
            busca o que já foi publicado, conversa com especialistas, lê artigos
            científicos e só depois começa a fazer um rascunho, no papel ou
            computador, de como pode apresentar aquela história.
          </Paragraph>
          <Paragraph>
            Ainda que consigam vivenciar um processo criativo no cotidiano
            profissional, Sacagami e Gomes ressaltam que alguns processos
            precisam ser mais práticos quando lidam com prazos apertados, assim
            como é feito por Pignato. Um exemplo citado por Gomes é a escolha
            por traços mais simples, sem muitos detalhes, para suas ilustrações.
          </Paragraph>
          <Quote>
            Uma coisa que eu aprendi foi abrir mão. Fez, não deu certo? Tudo
            bem, esquece, vai para a próxima. No jornal diário a graça é essa.
          </Quote>
          <Paragraph>
            Mesmo que faça uso desse tipo de recurso, ele também afirma que às
            vezes, no “susto”, correndo contra o tempo, podem surgir soluções
            novas e criativas para um projeto. Guilherme Prímola, editor de arte
            do <i>Metrópoles</i>, reforça a ideia de que a experimentação está
            vinculada ao prazo: “O limite da experimentação é só dar tempo. É o
            tempo de produzir e também de ficar legal no ar”. Ele também afirma
            que já lidou com designers que não entendiam muito de tecnologia,
            tentaram inovar demais e, no fim, a página não funcionou
            corretamente.
          </Paragraph>
          <Paragraph>
            A ilustração foi citada por Prímola, Gomes e Sacagami como um
            recurso que gostam de utilizar, mas com um olhar crítico em relação
            à sua necessidade a depender da pauta. É, portanto, apenas mais um
            recurso para compor uma narrativa visual. Para além dos recursos a
            serem utilizados, Falcão indica diferentes caminhos e possibilidades
            para uma narrativa visual. “Uma delas é você contar uma história
            paralela à pauta de maneira visual; você representar uma
            interpretação sua da pauta; você dar voz ao autor; você criar um
            universo visual que complementa, que cria uma ambiência para aquilo
            que está sendo lido”.
          </Paragraph>
          <Paragraph>
            A editora de arte do <i>UOL</i>, Gisele Pungan, explica que busca
            dar liberdade para que os designers possam criar. Antes de
            distribuir as demandas para a equipe, analisa os trabalhos e
            referências dos designers para checar se dialogam com a pauta. Às
            vezes, opta pelo processo inverso para que os profissionais tenham
            experiência com algo novo — o que é confirmado por Yasmin Ayumi,
            designer freelancer do <i>UOL</i>.
          </Paragraph>
          <Paragraph>
            Os editores de arte, Pungan e René Cardillo, enviam a reportagem que
            Ayumi deve ilustrar, indicando se querem um traço específico ou a
            deixando livre para propor. Essa indicação do estilo do traço às
            vezes é necessária devido ao{' '}
            <a
              href="https://www.behance.net/yasminayumi"
              target="_blank"
              rel="noopener noreferrer"
            >
              portfólio eclético de Ayumi
            </a>
            , que demonstra domínio sobre diferentes técnicas — uma vantagem
            para oportunidades de trabalho, segundo ela. A sua preferência é por
            ilustrações vetoriais, mas também gosta de explorar o 3D e o{' '}
            <span>lettering</span>.
          </Paragraph>
          <TextBox title="Lettering">
            Desenho de letras feito analogica ou digitalmente. Uma letra não é
            necessariamente única, podendo ser copiadas para garantir um aspecto
            uniforme. A caligrafia, por outro lado, consiste em escrever letras,
            sendo obrigatoriamente feita à mão, o que torna cada letra única.
          </TextBox>
          <Paragraph>
            A designer conta que as etapas do seu processo de criação são bem
            definidas. Depois de ler o texto enviado pelos editores, ela indica
            quais ilustrações irá fazer. Se as ideias forem aprovadas, busca por
            referências no Pinterest, cria os rascunhos e os envia para
            aprovação. Depois de aprovado, faz as versões finais. Esse processo
            leva por volta de duas semanas.
          </Paragraph>
          <Paragraph>
            Diferentemente da sua experiência ilustrando para revistas
            impressas, cuja aprovação passa por vários profissionais, no{' '}
            <i>UOL</i> os editores de arte costumam dar sugestões para a
            melhoria do trabalho, mas Ayumi não chega a ter que alterar o
            trabalho por completo. Enviar a eles cada etapa da sua produção
            também ajuda a diminuir essas possíveis alterações. Afinal, quanto
            maior o planejamento e a atenção em cada projeto, menores as chances
            de ser um processo – ou travessia – cheia de obstáculos.
          </Paragraph>
        </Article>
      </Content>
      <FooterLeiaMais />
    </div>
  );
};

export default ProcessoCriativo;
