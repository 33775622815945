/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import { Article, Image, Title } from './styles';
import SectionTag from '../SectionTag';

const SectionBox = ({ sectionTag, title, url, type, imageLink }) => {
  return (
    <Article>
      <Link to={url}>
        <SectionTag type={type || 'default'} sectionTag={sectionTag} />
      </Link>
      <Image imageLink={imageLink}>
        <Link to={url}>{title}</Link>
      </Image>{' '}
      <Link to={url}>
        <Title>{title}</Title>
      </Link>
    </Article>
  );
};

export default SectionBox;
