import styled from 'styled-components';

export const Container = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.75rem 0;
  background: ${({ theme }) => theme.backgroundDark};
`;

export const Content = styled.div`
  color: ${({ theme }) => theme.textLight};
  font: 'Raleway', sans-serif;
  text-align: center;
  width: 90%;

  @media (min-width: 600px) {
    width: 590px;
    padding: 2rem 0;
  }

  @media (min-width: 1000px) {
    width: 970px;
  }

  @media (min-width: 1200px) {
    width: 1170px;
  }
`;

export const FooterLabel = styled.div`
  @media (min-width: 600px) {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;

    > div {
      padding-left: 1rem;
    }
  }
`;

export const TitleContainer = styled.div`
  @media (min-width: 600px) {
    border-right: 1px solid #fff;
  }
`;

export const Title = styled.h1`
  font: 400 1.3rem 'Josefin Sans', sans-serif;
  font-size: 1.4rem;

  @media (min-width: 600px) {
    font-size: 1.7rem;
    padding-right: 1rem;
    text-align: right;
  }
`;

export const Subtitle = styled.h3`
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 300;
  font-size: 0.75rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  padding-top: 5px;

  @media (min-width: 600px) {
    text-align: right;
    padding-top: 2px;
    padding-right: 1rem;
    font-size: 0.9rem;
    margin-bottom: 0;
  }
`;

export const ItemGroup = styled.div`
  font: 0.75rem 'Raleway', sans-serif;
  line-height: 16px;
  padding-bottom: 1.2rem;
  text-transform: uppercase;

  @media (min-width: 600px) {
    text-align: left;
    font-size: 0.9rem;
    line-height: 1.3rem;
    padding-bottom: 0;
    align-self: center;
    margin-top: ${props => (props.nomargin ? 'unset' : '2rem')};
  }
`;

export const ItemLabel = styled.p``;

export const ItemText = styled.p`
  font-weight: 700;
  text-transform: ${props => (props.lowercase ? 'lowercase' : 'uppercase')};
`;

export const Credits = styled.div`
  padding-top: 2rem;

  @media (min-width: 600px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
  }

  @media (min-width: 900px) {
    max-width: 90%;
  }
`;
