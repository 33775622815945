/* eslint-disable no-unused-vars */
import React from 'react';
import { Link } from 'react-router-dom';
import Article from '../../components/Article';
import FooterLeiaMais from '../../components/FooterLeiaMais';
import Header from '../../components/Header';
import OpeningImage from '../../components/Image';
import Quote from '../../components/Quote';
import ArticleHeader from '../../components/ArticleHeader';
import Paragraph from '../../components/Paragraph';
import TextBox from '../../components/TextBox';
import { Content, Subtitle } from './styles';

const Rotina = () => {
  return (
    <>
      <Header />
      <OpeningImage
        imageUrl="images/abertura/rotina-pessoal-da-arte"
        alt="Colagem com fundo com cor salmão claro. Há um recorte de mãos mexendo em um notebook. Ao lado do notebook, há um relógio analógico com filtro com a cor azul. O ponteiro indica 17 horas. Atrás dele, há um segundo relógio, mais transparente, cujo ponteiro está às 19 horas."
      />
      <Content>
        <Article>
          <ArticleHeader
            sectionTagType="default"
            sectionTagName="Rotina"
            title="O dia a dia na editoria de arte (ou fora dela)"
            date="12 de julho de 2021"
            breakline
          />
          <Paragraph>
            O cenário de redação cheia de gente e de movimento foi substituído
            pela calmaria e a solidão do trabalho remoto. A pandemia da Covid-19
            provocou mudanças do dia para a noite e fez com que muitos
            profissionais passassem a trabalhar de suas casas. Entrevistados que
            ocupam cargos de chefia falaram abertamente sobre estarem
            trabalhando mais do que oito horas por dia, ficando “on-line” o
            tempo todo.
          </Paragraph>
          <Paragraph>
            Ainda que novas dificuldades tenham surgido, como a própria
            comunicação entre colegas de trabalho, outras permanecem as mesmas
            para os profissionais entrevistados: prazos curtos, alta demanda e
            equipes reduzidas. Mas, antes de explorar essas questões, vale
            explicar quais são as (várias) atividades desempenhadas por esses
            profissionais.
          </Paragraph>
          <Subtitle>Afinal, o que o “pessoal da arte” faz?</Subtitle>
          <Paragraph>
            O trabalho da equipe responsável pelo design é, majoritariamente, de
            prestação de serviços, atendendo as demandas – editoriais e
            institucionais – vindas da redação. As equipes têm liberdade para
            propor pautas, mas falta tempo e pessoal para que isso aconteça de
            forma efetiva, por isso o desenvolvimento de projetos planejados
            pela própria equipe de arte (também nomeada de “imagem” ou
            “multimídia”) são bem mais pontuais – aqueles que ocupam cargos de
            chefia costumam dar mais sugestões de possíveis coberturas. Mesmo
            que nem sempre tenham influência sobre o conteúdo, conseguem
            contribuir em relação ao formato e recursos a serem utilizados para
            a melhor apresentação da notícia.
          </Paragraph>
          <Paragraph>
            As atividades diárias variam de acordo com o cargo ocupado. Aqueles
            que estão em posição de chefia têm um papel de gerenciar as demandas
            e repassá-las para a sua equipe. Essa decisão de quem faz o que é
            pautada pelo domínio e área de interesse dos profissionais e, é
            claro, pelo tempo disponível de cada um.
          </Paragraph>
          <Paragraph>
            Gisele Pungan divide a função de editora de arte do <i>UOL</i> com
            René Cardillo. Ela se dedica à parte inicial do processo, o
            planejamento e a distribuição de demandas, além de direcionar a
            melhor solução para cada pedido. “A gente trabalha bem integrado,
            ele [René Cardillo] também vê os pedidos desde o começo, também pode
            ajudar a imaginar como que aquilo vai ser feito e eu também dou as
            minhas opiniões e orientações sobre estética e finalização”.
          </Paragraph>
          <TextBox>
            O design aparece distribuído em um fluxo de produção em rede,
            segundo Ary Moraes em{' '}
            <i>Design de Notícias – A Acessibilidade do Cotidiano</i> (p.134 e
            135). As fases podem ser resumidas em:
            <br /> <b>Projeto</b>: Buscar um discurso que consiga explicar a
            notícia para os leitores de acordo com a linha editorial do jornal e
            de forma viável. <br />
            <b>Planejamento</b>: Colocar o projeto em andamento; definir prazos,
            valores (se necessário) e equipes que irão contribuir para o
            trabalho.
            <br /> <b>Direção</b>: Manter o trabalho dentro dos limites traçados
            no projeto. Exige habilidades em design, edição, redação, produção
            gráfica, fotografia, infografia, entre outros.
          </TextBox>
          <Paragraph>
            Eles lideram uma equipe de dez profissionais, que dão conta não só
            da apresentação visual das matérias – que inclui ilustrações,
            infográficos e <i>layout</i> de página para matérias especiais –,
            mas de demandas como vinhetas para vídeo, cenário para um novo
            programa, peças de comunicação para eventos on-line e templates para
            as redes sociais. Por isso, também contratam freelancers para
            trabalhos específicos — caso da designer e ilustradora Yasmin Ayumi.
            O <i>UOL</i> é seu cliente fixo, ou seja, ela dedica algumas horas
            todos os dias para as demandas do portal, que vão de ilustrações
            para reportagens à identidade visual de uma campanha, por exemplo.
          </Paragraph>
          <Paragraph>
            Essa mescla entre produção institucional e editorial não acontece só
            no <i>UOL</i>. Editor de arte do <i>Metrópoles</i> desde 2015,
            Guilherme Prímola tem até que colocar a mão na massa e produzir
            peças diante da falta de tempo e de equipe, formada por três pessoas
            de design e três de vídeo. “É pouca gente, mas a gente é
            multidisciplinar. Tem uma ilustradora que também é uma boa
            infografista, um designer que é um bom diretor de arte. Eu faço
            design de interface, mas também sei fazer um pouco de{' '}
            <span>Motion Design </span>”, diz.
          </Paragraph>
          <TextBox title="Motion Design ">
            Ramificação do Design Gráfico que utiliza recursos visuais e
            elementos gráficos em animações 2D e 3D. A técnica é empregada em
            infográficos, vinhetas, gifs para as redes sociais, logos animados,
            entre outros.
          </TextBox>
          <Paragraph>
            A designer de informação Victória Sacagami, da{' '}
            <i>Gênero e Número (GN)</i>, lida com uma equipe ainda menor,
            composta por ela e a diretora de arte. Ela conta que as duas se
            dividem entre demandas editoriais e institucionais. “Eu pego mais
            dia a dia, conteúdo de redes, visualizações e ela [diretora de arte]
            fica mais no institucional”, diz. Na <i>GN</i>, Sacagami cria
            ilustrações e, principalmente, visualizações de dados com versões
            para desktop, dispositivos móveis e redes sociais.
          </Paragraph>
          <Paragraph>
            A função também é desempenhada por Catarina Pignato, infografista e
            ilustradora da <i>Folha de S.Paulo</i>. Diferentemente da{' '}
            <i>Gênero e Número</i>, o núcleo de imagem da <i>Folha</i> conta com
            um número maior de profissionais, que são infografistas,
            diagramadores, tratadores de foto e designers de interface. “A gente
            trata de tudo que é imagem”, diz Pignato. Ela e mais quatro
            infografistas se revezam para atender às demandas diárias de
            gráficos, que são feitos prioritariamente para a edição digital do
            jornal, segundo a designer. “Tudo o que está no impresso está no
            on-line, nem tudo que está no on-line está no impresso”. A
            infografista e ilustradora acaba produzindo três versões de uma
            mesma infografia, uma para o impresso e outras duas para o digital
            (desktop e mobile). Também “cobre buraco” criando ilustrações que,
            como apontado pela própria, em um cenário ideal sem redução de
            equipe seria tarefa de outros profissionais.
          </Paragraph>
          <Paragraph>
            O papel desempenhado por Simon Ducroquet no jornal <i>O Globo</i> e
            o da equipe de infografia do <i>Nexo Jornal</i> foge à regra. Em
            ambos os casos, eles ficam responsáveis não apenas pela apresentação
            visual, como também propõem pauta, planejam e executam o conteúdo.
            Esse modelo está mais alinhado ao que vem sendo aplicado em jornais
            como <i>The New York Times</i>, referência mais citada pelos
            entrevistados, que possui equipes específicas para produção de
            conteúdos com narrativas não convencionais – que explora recursos
            como interatividade, realidade virtual, vídeos, áudios, entre
            outros.
          </Paragraph>
          <Paragraph>
            Além de propor pautas, Ducroquet se envolve com o tratamento de
            dados em um nível mais iniciante para visualizações. Dentro da
            visualização, também faz o design e a parte de programação
            utilizando softwares de linguagens. Apesar de ainda não ter feito
            para <i>O Globo</i>, também produz conteúdos nos formatos de{' '}
            <a
              href="https://cargocollective.com/ducroquet/TERRA-DE-GIGANTES"
              target="_blank"
              rel="noopener noreferrer"
            >
              animação
            </a>{' '}
            e{' '}
            <a
              href="https://www.youtube.com/watch?v=JhpX3XBidpc&feature=emb_logo"
              target="_blank"
              rel="noopener noreferrer"
            >
              vídeo
            </a>
            .
          </Paragraph>
          <Paragraph>
            Lucas Gomes, designer de informação do <i>Nexo Jornal</i>, explica
            que a equipe de infografia é composta por cinco pessoas, entre elas,
            dois cientistas de dados. Esse grupo faz uma reunião de planejamento
            semanal para definir as atividades a serem feitas, que incluem de
            duas a três publicações de gráficos por semana na{' '}
            <a
              href="https://www.nexojornal.com.br/grafico/"
              target="_blank"
              rel="noopener noreferrer"
            >
              seção “Gráfico” do jornal
            </a>
            . Também não deixam de aceitar sugestões de pautas de outros membros
            da redação e dos assinantes do <i>Nexo</i>. Gomes calcula que apenas
            5% do seu tempo de trabalho é gasto com demandas vindas de redatores
            e que gráficos simples são resolvidos por eles próprios.
          </Paragraph>
          <Paragraph>
            Em 2016, o designer Guilherme Falcão tornou-se diretor de arte do{' '}
            <i>Nexo Jornal</i> e passou a cumprir a mesma função na{' '}
            <i>Revista Gama</i>, lançada em 2020. No dia 28 de maio de 2021, ele
            anunciou em seu perfil do Instagram a sua saída do Grupo Nexo. Seu
            trabalho consistia em supervisionar o que estava sendo produzido
            pela equipe de infografia com o objetivo de manter a cultura visual
            da publicação. Também coordenava uma outra equipe, composta por duas
            pessoas, responsável por atender às demandas de comunicação, criar
            peças visuais para as matérias e desenvolver layouts de páginas para
            conteúdos especiais. Tudo isso sem deixar de ser uma equipe
            propositiva, segundo afirmou Falcão na{' '}
            <a
              href="https://www.youtube.com/watch?v=DisvdHgDIqo&list=PLAE4zdwPT_XPGcvEg5bY6YF-JldVKJz3b&index=16"
              target="_blank"
              rel="noopener noreferrer"
            >
              oficina Design como ponte
            </a>
            , oferecida on-line e gratuitamente no Festival Nexo + Nexo
            Políticas Públicas. O modelo do <i>Nexo</i>, em que equipes são
            divididas de forma mais direcionada, parece ser uma forma mais
            assertiva de atender às demandas com maior dedicação.
          </Paragraph>
          <Paragraph>
            Dentre os entrevistados, a maior vivência em redação é a de Fabio
            Sales, com 24 anos de experiência. Sua rotina envolve participar
            ativamente das três reuniões de pauta diárias da redação, que
            definem a produção de conteúdo para o digital e o impresso. Também
            tem papel importante nas decisões sobre melhor formato para um
            determinado conteúdo, orientando jornalistas e sua equipe que, assim
            como na <i>Folha</i>, é composta por fotógrafos, diagramadores, web
            designers, infografistas, alguns ilustradores – hoje a maioria é
            contratado como freelancer – e jornalistas visuais. Sobre esse
            último grupo, Sales diz que também foi diminuindo ao longo dos anos:
            pessoas deixaram o <i>Estadão</i> e não foram contratados outros
            profissionais para o cargo. O editor explica que muito do que está
            sendo produzido no jornal deve seguir o projeto gráfico, por isso
            não demanda um acompanhamento tão de perto. Busca dar uma maior
            atenção às coberturas especiais e planejadas, como eleições, eventos
            esportivos e efemérides.
          </Paragraph>
          <Paragraph>
            Além de editores, diretores e jornalistas, parte desses
            profissionais também se relaciona com a equipe de tecnologia,
            cientistas de dados, equipe de marketing e de redes sociais. São
            muitas pessoas envolvidas para fazer o trabalho acontecer.
          </Paragraph>
          <Subtitle>De volta às dificuldades</Subtitle>
          <Paragraph>
            A periodicidade da publicação é determinante para diferenciar as
            rotinas desses profissionais. Na <i>Folha</i>, Catarina Pignato faz
            até seis infografias por dia, tocando um projeto maior nesse meio
            tempo, como as{' '}
            <a
              href="https://arte.folha.uol.com.br/cotidiano/2021/100-paulistanos/"
              target="_blank"
              rel="noopener noreferrer"
            >
              cem ilustrações que fez para o aniversário de São Paulo em 2021
            </a>
            . Já Victória Sacagami, na <i>Gênero e Número</i>, se divide entre
            diferentes demandas, mas em um prazo mais justo. Ela produz até duas
            visualizações em um prazo de três a quatro dias para uma newsletter
            semanal – disponível apenas para assinantes. A designer possui uma
            carga horária diferente dos demais entrevistados, de 20 horas
            semanais.
          </Paragraph>
          <Paragraph>
            Ainda que a produção para o impresso exija um outro ritmo, Gisele
            Pungan e Guilherme Prímola, que estão em portais nativos digitais,
            também atendem a demandas com prazos “para ontem” devido à
            publicação diária. O que se reflete diretamente no resultado final,
            segundo Prímola: “É claro que isso impacta na qualidade, tem coisa
            que não dá para ficar com o melhor visual, mas foi o que a gente
            conseguiu fazer naquele prazo. Dependendo da matéria, a gente faz
            uma outra versão depois”. A correria por conta dos prazos não está
            apenas relacionada ao ritmo inerente ao jornalismo. A falta de
            pessoas na equipe, resultando no acúmulo de funções, também
            interfere nisso.
          </Paragraph>
          <Paragraph>
            No geral, os prazos variam muito: tem as demandas para o mesmo dia,
            aquelas com um prazo de alguns dias e materiais especiais, que
            costumam ter de duas a três semanas para irem ao ar. Apesar de estar
            em um jornal diário, a produção de Simon Ducroquet está concentrada
            em narrativas visuais que demandam um prazo maior. Ele não atende às
            mesmas demandas que o restante da editoria de arte e se mantém mais
            conectado com Alessandro Alvim, editor-executivo visual do{' '}
            <i>O Globo</i>.
          </Paragraph>
          <Paragraph>
            Pignato e Pungan curtem o ritmo da produção jornalística. Ambas
            afirmam que gostam quando lidam com uma demanda por algumas horas, a
            entregam e depois já surge uma próxima para ser resolvida. A editora
            de arte do <i>UOL</i> trabalhou em diferentes jornais, entre eles, a{' '}
            <i>Folha de S.Paulo</i> e lá aprendeu a ser mais ágil para resolver
            as tarefas. “Essa objetividade que o jornalismo traz para mim
            facilita a rapidez e é uma coisa que eu gosto”, conta.
          </Paragraph>
          <Paragraph>
            Em contrapartida, até mesmo Pungan citou o prazo apertado como sendo
            uma das maiores dificuldades no trabalho. Para Lucas Gomes, “é uma
            coisa que faz parte do trabalho, as coisas têm tempo para serem
            vistas”. Uma dificuldade entendida por ele como “boa” é a
            necessidade de estar sempre pensando em materiais novos para
            produzir, que não se tornem repetitivos.
          </Paragraph>
          <Quote>
            Essa objetividade que o jornalismo traz para mim facilita a rapidez
            e é uma coisa que eu gosto
          </Quote>
          <Paragraph>
            Uma outra dificuldade levantada por Ducroquet é a ideia de que a
            editoria de arte deve pensar exclusivamente no visual, impedindo que
            os profissionais tenham trocas sobre o conteúdo, para além do
            formato. “Acho que é importante ter, por exemplo, reuniões de pauta,
            ter reunião com editor de conteúdo para você amadurecer como
            jornalista e entender os conteúdos, entender as questões envolvidas,
            entender para onde que a notícia está indo”. Ele também cita as
            dificuldades tecnológicas no desenvolvimento de materiais
            interativos, que fogem do sistema de publicação padrão do site,
            orientado para texto e foto.
          </Paragraph>
          <Paragraph>
            Fabio Sales aponta que a falta de domínio técnico, dele e de outros
            profissionais da equipe, acaba dificultando o trabalho. Outro fator
            é a crise do jornalismo. &ldquo;A gente vem percebendo uma
            diminuição da atenção das pessoas em relação a produção
            jornalística, um interesse maior por acompanhamento por rede social.
            A gente também vem passando por reduções de quadro, isso também
            afeta muito&ldquo;, conclui.
          </Paragraph>
          <Paragraph>
            Esse crescimento da busca por informação em redes sociais exige que
            os jornais passem a produzir narrativas para diferentes mídias
            digitais, criando novas dinâmicas na redação e mudanças nos perfis
            dos profissionais. Essa discussão é abordada na seção{' '}
            <Link to="/jornalismo">
              <b>Jornalismo e Design: relação de longa data</b>
            </Link>
            .
          </Paragraph>
          <br />
          <br />
        </Article>
      </Content>
      <FooterLeiaMais />
    </>
  );
};

export default Rotina;
