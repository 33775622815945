import styled from 'styled-components';

export const Meta = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
`;

export const ArticleTitle = styled.h1`
  font-size: 2.3rem;
  line-height: 2.6rem;
  text-align: center;
  margin: 2rem 0;

  @media (min-width: 600px) {
    font-size: 3.2em;
    max-width: ${props => (props.breakline ? '540px' : '800px')};
    line-height: 3.5rem;
  }
`;

export const Date = styled.time`
  font: 0.93rem 'Raleway', sans-serif;
`;
