/* eslint-disable react/prop-types */
import React from 'react';
import { ImageContainer } from './styles';

const OpeningImage = ({ imageUrl, alt }) => {
  return (
    <ImageContainer>
      <picture>
        <source media="(min-width:600px)" srcSet={`${imageUrl}_large.png`} />
        <img src={`${imageUrl}_large.png`} alt={alt} />
      </picture>
    </ImageContainer>
  );
};

export default OpeningImage;
